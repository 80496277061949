import React, { Fragment } from 'react';
import RunningTextManagement from './RunningTextManagement';
import ServiceManagement from './ServiceManagement';

const OthersHomepageManagement = () => {
    return (
        <Fragment>
            <RunningTextManagement/>
            <ServiceManagement/>
            {/*<PopupManagement/>*/}
        </Fragment>
    );
};

export default OthersHomepageManagement;