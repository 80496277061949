import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { API_CDN_CNF, API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import { FilePond } from 'react-filepond';

const FooterImageThemeCard = () => {
    const [data, setData] = useState({
        images: []
    })

    const [mainImage, setMainImage] = useState([])

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_THEME_FOOTER_IG').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })
            if (json.images && json.images.length > 0) {

                setMainImage([
                    ...json.images.map((v, i) => {
                        return {
                            source: v,
                            options: { type: 'local' }
                        }
                    })
                ])
            }
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])


    const handlerSubmit = (e) => {
        e.preventDefault();

        let imagesServerIds = []

        mainImage.map((v, i) => {
            imagesServerIds = [...imagesServerIds, v.serverId]
        })

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_THEME_FOOTER_IG',
            value: JSON.stringify({
                ...data,
                images: imagesServerIds,
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }


    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage Footer Image Theme"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Photos (360 x 360) - 6
                                        Photos</Label>
                                    <FilePond
                                        // required
                                        allowMultiple={true}
                                        allowImageCrop={true}
                                        allowImageTransform={true}
                                        allowFileSizeValidation={true}
                                        imageResizeTargetHeight={360}
                                        files={mainImage}
                                        onupdatefiles={setMainImage}
                                        maxFiles={6}
                                        imageCropAspectRatio={'1:1'}
                                        acceptedFileTypes={['image/*']}
                                        maxFileSize={'1MB'}
                                        server={API_CDN_CNF}
                                        name="files"
                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        {/*<Btn attrBtn={{ color: 'light' }}>Cancel</Btn>*/}
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default FooterImageThemeCard;