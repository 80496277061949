import DataTable from 'react-data-table-component';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import axios from 'axios';
import { API_SKIN_ANALYZER_QUESTION } from '../../../api/server';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import SweetAlert from 'sweetalert2';
import { Btn } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';

export const SkinAnalyzerQuestionDataTableV2 = ({ setSelectedRows, selectedRows }) => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [toggleCleared, setToggleCleared] = useState(false);

    const nav = useNavigate();

    const [tablePagination, setTablePagination] = useState({
        totalRows: 0,
        rowPerPage: 10,
        currentPage: 1,
    })

    const [formsText, setFormsText] = useState({
        search: ''
    })

    const tableColumns = [
        {
            name: 'Text',
            selector: (row) => row.question_text,
            sortable: true,
            center: false,
            allowOverflow: false,
            wrap: true,
        },
        {
            name: '',
            selector: (row) => row.edit,
            sortable: true,
            center: true,
        },
    ];

    useEffect(() => {
        axios.get(API_SKIN_ANALYZER_QUESTION + '?pagination=' + CreatePaginationEncoding(10, 0))
            .then(r => {
                if (r.status === 200) {
                    return r.data['data'];
                }
            }).then(json => {

            if (json['data'].length > 0) {
                let _d = []

                json['data'].map((v, i) => {
                    _d = [..._d, {
                        ...v,
                        edit:
                            <Btn attrBtn={{
                                onClick: () => {
                                    nav('create?edit=' + v.id)
                                },
                                className: 'btn btn-red btn-md',
                                type: 'button',
                                color: 'warning',
                            }}>Edit</Btn>
                    }]
                })

                setData(_d)
                setTablePagination({
                    ...tablePagination,
                    totalRows: json['count']['all']
                })
            }
            // setData(json['data'])
        }).catch(() => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
            })
        })
    }, [])

    const handleDelete = () => {
        if (selectedRows.length > 0) {
            SweetAlert.fire({
                title: 'Delete?',
                html: 'Are you sure want to delete this?',
                icon: 'error',
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Delete',
                reverseButtons: true,
                showCancelButton: true,
            }).then((e) => {
                if (e.isConfirmed) {
                    axios.delete(API_SKIN_ANALYZER_QUESTION + '?' + selectedRows.map((v, i) => {
                        return 'id=' + v.id
                    }).join('&')).then(r => {
                        if (r.status === 204) {
                            SweetAlert.fire({
                                title: 'Good Job!',
                                text: 'Data Deleted',
                                icon: 'success'
                            }).then(() => {
                                nav('/content/skin-analyzer/question');
                            })
                        }
                    }).catch(() => {
                        SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                        })
                    })
                }
            });
        }
    }

    const handleLoadWithFilter = (paginationLimit = tablePagination.rowPerPage, paginationOffset = 0, filter) => {
        setIsLoading(true);
        axios.get(API_SKIN_ANALYZER_QUESTION + '?pagination=' + CreatePaginationEncoding(paginationLimit, paginationOffset) + '&filter=' + CreateFilterEncoding({ ...filter })).then(r => {
            if (r.status === 200) {
                return r.data['data'];
            }
        }).then(json => {
            if (json['data'] && json['data'].length > 0) {
                let _d = []

                json['data'].map((v, i) => {
                    _d = [..._d, {
                        ...v,
                        edit:
                            <Btn attrBtn={{
                                onClick: () => {
                                    nav('create?edit=' + v.id)
                                },
                                className: 'btn btn-red btn-md',
                                type: 'button',
                                color: 'warning',
                            }}>Edit</Btn>
                    }]
                })

                setData(_d)
                setTablePagination({
                    ...tablePagination,
                    totalRows: json['count']['all']
                })
            } else {
                setData([])
            }
        }).catch((err) => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                console.log(err)
            })
        })
        setIsLoading(false);
    }

    const onSearchSubmit = (e) => {
        e.preventDefault()
        handleLoadWithFilter(tablePagination.rowPerPage, (tablePagination.currentPage - 1) * tablePagination.rowPerPage, { question_text: formsText.search })
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        setTablePagination({ ...tablePagination, currentPage: page })
        handleLoadWithFilter(tablePagination.rowPerPage, (page - 1) * tablePagination.rowPerPage, { question_text: formsText.search })
    };

    const onPaginationRowsChangePage = (newPerPage, page) => {
        setTablePagination({
            ...tablePagination,
            rowPerPage: newPerPage
        })
        handleLoadWithFilter(newPerPage, (page - 1) * newPerPage, { question_text: formsText.search })
    }

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    return <>
        <Container fluid={true} className="datatables">
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="pb-0">
                            <Row>
                                <Col>
                                    <Link to={'create'}>
                                        <Btn attrBtn={{
                                            onClick: () => {
                                                // setDrawer({ ...drawer, create: true })
                                            },
                                            className: 'btn btn-success btn-md',
                                            type: 'button'
                                        }}>New Question</Btn>
                                    </Link>
                                </Col>

                                {selectedRows.length > 0 &&
                                    <Col>
                                        <Row xs="auto" className={'justify-content-md-end'}>
                                            <Col>
                                                <Btn attrBtn={{
                                                    onClick: handleDelete,
                                                    className: 'btn btn-red btn-md',
                                                    type: 'button',
                                                    color: 'danger'
                                                }}>Delete</Btn>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Col md={12}>
                                <Form onSubmit={onSearchSubmit}>
                                    <Row className="justify-content-start align-items-end">
                                        <Col md="auto">
                                            <FormGroup className="row">
                                                <Label htmlFor="exampleFormControlSelect3">Search</Label>
                                                <Col sm="12">
                                                    <Input className="form-control" type="text"
                                                           placeholder={'Search Here'} onChange={(e) => setFormsText({
                                                        ...formsText,
                                                        search: e.target.value
                                                    })}/>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md="auto">
                                            <Label htmlFor="exampleFormControlSelect3"></Label>
                                            <FormGroup className="row">
                                                <Btn attrBtn={{
                                                    color: 'primary',
                                                    className: 'm-r-15',
                                                    type: 'submit'
                                                }}>Search</Btn>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>

                            <Col md={12}>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={tablePagination.totalRows}
                                    onChangeRowsPerPage={onPaginationRowsChangePage}
                                    onChangePage={onPageChange}
                                    striped={true}
                                    center={true}
                                    selectableRows
                                    persistTableHead
                                    onSelectedRowsChange={handleRowSelected}
                                    clearSelectedRows={toggleCleared}
                                />
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>


    </>
}