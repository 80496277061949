import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { HexColorPicker } from 'react-colorful';

export const TextSectionManagement = () => {
    const [data, setData] = useState({
        title: '',
        content: '',
        background_color: '',
        text_color: '',
    })
    const [editorData, setEditorData] = useState('')

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_ABOUT_US_TEXT_SECTION').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })
            setEditorData(json.content)
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])

    const handlerSubmit = (e) => {
        e.preventDefault();


        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_ABOUT_US_TEXT_SECTION',
            value: JSON.stringify({
                ...data,
                content: editorData,
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }


    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage About Us - Text Only Section"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Title</Label>
                                    <Input className="form-control" type="text"
                                           value={data.title}
                                           onChange={e => setData({ ...data, title: e.target.value })}
                                           placeholder="ABCDEF"/>
                                </FormGroup>
                                <Row>
                                    <Col md={6} sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">Background Color</Label>
                                            <Row>
                                                <Col md="auto">
                                                    <HexColorPicker color={data.background_color} onChange={(v) => {
                                                        setData({
                                                            ...data,
                                                            background_color: v
                                                        })
                                                    }}/>
                                                </Col>
                                                <Col md="auto">
                                                    <Input className="form-control"
                                                           value={data.background_color}
                                                           onChange={e => setData({
                                                               ...data,
                                                               background_color: e.target.value
                                                           })}
                                                           type="text"/>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">Text Color</Label>
                                            <Row>
                                                <Col md="auto">
                                                    <HexColorPicker color={data.text_color} onChange={(v) => {
                                                        setData({
                                                            ...data,
                                                            text_color: v
                                                        })
                                                    }}/>
                                                </Col>
                                                <Col md="auto">
                                                    <Input className="form-control"
                                                           value={data.text_color}
                                                           onChange={e => setData({
                                                               ...data,
                                                               text_color: e.target.value
                                                           })}
                                                           type="text"/>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Color Preview</Label>
                                    <Row style={{
                                        width: '100%',
                                        height: '60px',
                                        backgroundColor: data.background_color,
                                        color: data.text_color,
                                        textAlign: 'center'
                                    }} className="justify-content-center align-content-center">
                                        <Col>
                                            <h3 style={{ margin: 0 }}>TEST 1234676</h3>
                                        </Col>
                                    </Row>
                                </FormGroup>


                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Content</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorData}
                                        config={{
                                            extraPlugins: [MyCustomUploadAdapterPlugin],
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
}