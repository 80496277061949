function combinationUtil(arr, n, r, index, data, i, res) {
    // console.log(res)
    if (index === r) {
        let temp = []

        for (let j = 0; j < r; j++) {
            temp = [...temp, data[j]]
            // console.log(data[j] + ' ');
        }

        res.push(temp)

        // console.log(temp)
        // console.log('-- NL');

        return temp;
    }

    // When no more elements are there
    // to put in data[]
    if (i >= n) {
        // console.log(res)
        return;
    }

    // current is included, put
    // next at next location
    data[index] = arr[i];
    combinationUtil(arr, n, r, index + 1, data, i + 1, res);

    // current is excluded, replace
    // it with next (Note that
    // i+1 is passed, but index is not changed)
    combinationUtil(arr, n, r, index, data, i + 1, res);

}

// The main function that prints
// all combinations of size r
// in arr[] of size n. This function
// mainly uses combinationUtil()
export function PrintCombination(arr, n, r) {
    let data = new Array(r);
    let res = [];
    combinationUtil(arr, n, r, 0, data, 0, res)
    return res
}