import React, { Fragment } from 'react';
import CreateProductCategoryForms from './forms';


const CreateProductCategory = ({ id }) => {
    return (
        <Fragment>
            <CreateProductCategoryForms id={id && id}/>
        </Fragment>
    )
}

export default CreateProductCategory