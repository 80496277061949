import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API_CONTACT_US } from '../../../api/server';
import { CreatePaginationEncoding } from '../../../Utils/Base64/request';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Btn } from '../../../AbstractElements';

export const CustomerContactDataTables = ({ setSelectedData }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(API_CONTACT_US + '/?pagination=' + CreatePaginationEncoding(100, 0)).then(r => {
            if (r.status === 200) {
                return r.data['data'];
            }
        }).then(json => {
            // json['data'] && setData(json['data'])
            let row = []

            if (json['data']) {
                json['data'].map((v, i) => {
                    row = [...row, generateRow(v)]
                })

                setData(row)
            }
        }).catch(() => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
            })
        })
    }, [])

    const handleResolve = (v) => {
        SweetAlert.fire({
            title: 'Resolve ?',
            html: 'Are you sure want to resolve this?',
            icon: 'warning',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Resolve',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.put(API_CONTACT_US + '/', {
                    ...v,
                    status: 2
                }).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire({
                            title: 'Good Job!',
                            text: 'Data Resolved',
                            icon: 'success'
                        }).then(() => {
                            nav('/company/store/customer-contact');
                        })
                    }
                }).catch(() => {
                    SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                    })
                })
            }
        });
    }
    const style2 = { width: 70, fontSize: 14, padding: 4 };
    const generateRow = (v) => (
        {
            ...v,
            action:
                <div>
                    <span>
                      <Btn attrBtn={{
                          style: style2,
                          className: 'btn btn-success btn-xs',
                          type: 'button',
                          onClick: () => {
                              setSelectedData(v)
                          }
                      }}>Detail</Btn>
                    </span>&nbsp;&nbsp;
                    <span>
                      <Btn attrBtn={{
                          style: style2,
                          className: 'btn btn-danger btn-xs',
                          type: 'button',
                          onClick: () => {
                              handleResolve(v)
                          }
                      }}>Resolve</Btn>
                    </span>
                </div>
        }
    )


    const tableColumns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
            center: true,
        }, {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
            center: true,
        }, {
            name: 'Message',
            selector: (row) => row.message,
            sortable: true,
            center: true,
        }, {
            name: '',
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ];
    // const handleRowSelected = useCallback(state => {
    //     setSelectedRows(state.selectedRows);
    // }, []);
    //
    // const nav = useNavigate();
    //
    // const handleDelete = () => {
    //     if (selectedRows.length > 0) {
    //         SweetAlert.fire({
    //             title: 'Delete?',
    //             html: 'Are you sure want to delete this?',
    //             icon: 'error',
    //             cancelButtonText: 'Cancel',
    //             confirmButtonText: 'Delete',
    //             reverseButtons: true,
    //             showCancelButton: true,
    //         }).then((e) => {
    //             if (e.isConfirmed) {
    //                 axios.delete(API_STORE_PRODUCT_CATEGORY + '/?' + selectedRows.map((v, i) => {
    //                     return 'id=' + v.id
    //                 }).join('&')).then(r => {
    //                     if (r.status === 204) {
    //                         SweetAlert.fire({
    //                             title: 'Good Job!',
    //                             text: 'Data Deleted',
    //                             icon: 'success'
    //                         }).then(() => {
    //                             nav('/store/product/category-list');
    //                         })
    //                     }
    //                 }).catch(() => {
    //                     SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
    //                     })
    //                 })
    //             }
    //         });
    //     }
    // }

    return (
        <Fragment>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            {/*<CardHeader className="pb-0">*/}
                            {/*    <Row>*/}
                            {/*        <Col>*/}
                            {/*            /!*<Link to={'/store/product/category/create'}>*!/*/}
                            {/*            <Btn attrBtn={{*/}
                            {/*                onClick: () => {*/}
                            {/*                    setDrawer({ ...drawer, create: true })*/}
                            {/*                },*/}
                            {/*                className: 'btn btn-success btn-md',*/}
                            {/*                type: 'button'*/}
                            {/*            }}>New Product Category</Btn>*/}
                            {/*            /!*</Link>*!/*/}
                            {/*        </Col>*/}
                            {/*        <Col>*/}
                            {/*            <Row xs="auto" className={'justify-content-md-end'}>*/}
                            {/*                <Col>*/}
                            {/*                    <Btn attrBtn={{*/}
                            {/*                        onClick: () => {*/}
                            {/*                            setDrawer({ ...drawer, edit: true })*/}
                            {/*                            // setIsOpen(true)*/}
                            {/*                        },*/}
                            {/*                        className: 'btn btn-warning btn-md',*/}
                            {/*                        type: 'button'*/}
                            {/*                    }}>Edit</Btn>*/}
                            {/*                </Col>*/}
                            {/*                <Col>*/}
                            {/*                    <Btn attrBtn={{*/}
                            {/*                        onClick: handleDelete,*/}
                            {/*                        className: 'btn btn-red btn-md',*/}
                            {/*                        type: 'button',*/}
                            {/*                        color: 'danger'*/}
                            {/*                    }}>Delete</Btn>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*        </Col>*/}
                            {/*    </Row>*/}
                            {/*</CardHeader>*/}
                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    striped={true}
                                    center={true}
                                    selectableRows
                                    persistTableHead
                                    // onSelectedRowsChange={handleRowSelected}
                                    // clearSelectedRows={toggleCleared}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}