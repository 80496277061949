import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Media, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_COLLABORATION } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';
import { ProductTypeahead } from '../../../Components/Common/Component/ProductTypeahead';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';

const CollabManagementForms = () => {
    const [data, setData] = useState({
        active: false,
        navigation_title: '',
        content: '',
        related_products: []
    })

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('edit') !== null) {
            axios.get(API_COLLABORATION + '/?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({ id: parseInt(searchParams.get('edit')) })).then(r => {
                if (r.status === 200) {
                    return r.data['data'];
                }
            }).then(json => {
                setData(json['data'][0])
                setEditorData(json['data'][0]['content'])
                setSelectedData(json['data'][0]['related_products'])
            }).catch(() => {
                SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                })
            })
        }
    }, [])


    const nav = useNavigate()

    const handlerSubmit = (e) => {
        e.preventDefault();

        if (searchParams.get('edit') === null) {
            axios.post(API_COLLABORATION + '/', {
                ...data,
                content: editorData,
                related_products: selectedData
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/store/product/collaboration');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        } else {
            axios.put(API_COLLABORATION + '/', {
                ...data,
                content: editorData,
                related_products: selectedData
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/store/product/collaboration');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        }
        //
        // axios.post(API_CONFIGURATOR + '/', {
        //     key: 'CF_COLLAB',
        //     value: JSON.stringify({
        //         ...data,
        //         content: editorData,
        //         related_products: selectedData,
        //     })
        // }).then(r => {
        //     if (r.status === 204) {
        //         SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
        //     }
        // }).catch(() => {
        //     SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        // })
    }


    const [editorData, setEditorData] = useState('')
    const [selectedData, setSelectedData] = useState([])

    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage Collaboration"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <Media>
                                    <Label className="col-form-label m-r-10">Active ?</Label>
                                    <Media body className="text-end icon-state switch-lg">
                                        <Label className="switch">
                                            <Input type="checkbox" checked={data.active} onChange={() => {
                                                setData({ ...data, active: !data.active })
                                            }}/>
                                            <span className="switch-state bg-primary"/>
                                        </Label>
                                    </Media>
                                </Media>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Navigation Title</Label>
                                    <Input className="form-control" type="text"
                                           value={data.navigation_title}
                                           onChange={e => setData({ ...data, navigation_title: e.target.value })}
                                           placeholder="ABCDEF"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Related Product</Label>
                                    <ProductTypeahead multiple={true}
                                                      placeholder={'Please Select Product'}
                                                      onChange={(options) => {
                                                          setSelectedData(options.map((v, i) => v.id))
                                                      }}
                                                      defaultSelectedId={selectedData}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Content</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorData}
                                        config={{
                                            mediaEmbed: { previewsInData: true },
                                            extraPlugins: [MyCustomUploadAdapterPlugin],
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data)
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default CollabManagementForms;