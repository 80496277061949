import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Form, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import { ProductTypeahead } from '../../../Components/Common/Component/ProductTypeahead';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';

const PopupManagement = () => {

    const [selectedData, setSelectedData] = useState([])

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_ON_SALE').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setSelectedData([...json.data])
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])

    const handlerSubmit = (e) => {
        e.preventDefault();

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_ON_SALE',
            value: JSON.stringify({
                data: selectedData
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }

    return (
        <Fragment>
            <Card>
                <HeaderCard title="On Sale Product"/>
                <Form className="form theme-form" onSubmit={handlerSubmit}>
                    <CardBody>
                        <Row>
                            <Col>
                                <ProductTypeahead multiple={true}
                                                  placeholder={'Please Select Product'}
                                                  onChange={(options) => {
                                                      setSelectedData(options.map((v, i) => v.id))
                                                  }}
                                                  defaultSelectedId={selectedData}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default PopupManagement;