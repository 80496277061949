import React from 'react'

import { FilePond } from 'react-filepond'
import { API_CDN_CNF } from '../../../../api/server';


function CarouselImageUploader({ image, setImage }) {
    return (
        <>
            <FilePond
                required
                files={image}
                allowMultiple={false}
                onupdatefiles={setImage}
                maxFiles={1}
                // allowImageCrop={true}
                // imageCropAspectRatio={'1900:700'}
                acceptedFileTypes={['image/*']}
                allowFileSizeValidation={true}
                maxFileSize={'2MB'}
                server={API_CDN_CNF}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
            />
        </>

    )
}


export default CarouselImageUploader
