import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';

import 'react-modern-drawer/dist/index.css'
import { useNavigate } from 'react-router-dom';
import BlogCategoryForms from './Forms';
import Drawer from 'react-modern-drawer';
import { BlogCategoryDataTable } from './BlogCategoryDataTable';


const BlogCategoryList = () => {

    const [drawerState, setDrawerState] = React.useState({
        create: false,
        edit: false
    })

    const [selectedRows, setSelectedRows] = useState([]);
    const nav = useNavigate();

    const closeAllDrawer = () => {
        nav('/content/blog/category');
        setDrawerState({
            create: false,
            edit: false,
        })
    }

    return (
        <Fragment>
            <Breadcrumbs parent="Table" title="Blog Category List" mainTitle="Blog Category List"/>
            <BlogCategoryDataTable setDrawer={setDrawerState}
                                   drawer={drawerState}
                                   selectedRows={selectedRows}
                                   setSelectedRows={setSelectedRows}/>


            <Drawer
                open={drawerState.edit}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                {selectedRows.map((v, i) => {
                    return <BlogCategoryForms id={v.id}/>
                })}
            </Drawer>
            <Drawer
                open={drawerState.create}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <BlogCategoryForms/>
            </Drawer>
        </Fragment>
    );
};

export default BlogCategoryList;