import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';
import { CollaborationDataTable } from './CollaborationDataTable';

export const CollabManagement = () => {
    const [selectedRows, setSelectedRows] = useState([]);

    return (
        <Fragment>
            <Breadcrumbs parent="Promotion" title="Collaboration" mainTitle="Collaboration Management"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <CollaborationDataTable selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}