import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import Drawer from 'react-modern-drawer';
import PartnersForms from './PartnersForms';
import { PartnersDataTable } from './PartnersDataTable';


const StorePartnerList = () => {

    const [drawerState, setDrawerState] = React.useState({
        create: false,
        edit: false
    })

    const [selectedRows, setSelectedRows] = useState([]);
    const nav = useNavigate();

    const closeAllDrawer = () => {
        nav('/company/store/partner');
        setDrawerState({
            create: false,
            edit: false,
        })
    }

    return (
        <Fragment>
            <Breadcrumbs parent="Company" title="Offline Store & Partner" mainTitle="Offline Store & Partner"/>
            <PartnersDataTable setDrawer={setDrawerState}
                               drawer={drawerState}
                               selectedRows={selectedRows}
                               setSelectedRows={setSelectedRows}/>
            <Drawer
                open={drawerState.edit}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                {selectedRows.map((v, i) => {
                    return <PartnersForms id={v.id}/>
                })}
            </Drawer>
            <Drawer
                open={drawerState.create}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <PartnersForms/>
            </Drawer>
        </Fragment>
    );

};

export default StorePartnerList;