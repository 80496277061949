import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { Content, Post } from '../../../Constant';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '../../../Components/CK5/build-classic'
// import ClassicEditor from './ClassicEditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';
import { Btn } from '../../../AbstractElements';

const FormPost = () => {
    // ClassicEditor.builtinPlugins = [...ClassicEditor.builtinPlugins, Alignment]

    const [data, setData] = useState({
        content: ''
    })

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CT_SAVE_OUR_PLANET').then(r => {
            if (r.status === 200) {
                return r.data;
            }
        }).then(json => {
            json && setData({ ...json })
            json && setEditorData(json.content)

        }).catch(() => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
            })
        })
    }, [])

    const [editorData, setEditorData] = useState('')

    const nav = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post(API_CONFIGURATOR + '/', {
            key: 'CT_SAVE_OUR_PLANET',
            value: JSON.stringify({
                content: editorData
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }

    return (
        <Fragment>
            <Form className="row needs-validation" onSubmit={handleSubmit}>
                <Col sm="12">
                    <div className="email-wrapper">
                        <div className="theme-form">
                            <FormGroup>
                                <Label>{Content}:</Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={editorData}
                                    config={{
                                        extraPlugins: [MyCustomUploadAdapterPlugin],
                                        toolbar: {
                                            items: [
                                                'heading',
                                                '|',
                                                'bold',
                                                'italic',
                                                'link',
                                                'bulletedList',
                                                'numberedList',
                                                '|',
                                                'alignment',
                                                'outdent',
                                                'indent',
                                                'uploadImage',
                                                'blockQuote',
                                                'mediaEmbed',
                                                'undo',
                                                'redo'
                                            ]
                                        },
                                        mediaEmbed: {
                                            previewsInData: true
                                        }
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setEditorData(data)
                                    }}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </Col>
                <div className="btn-showcase">
                    <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{Post}</Btn>
                    <Btn attrBtn={{
                        color: 'light', onClick: () => {
                            nav('/content/blog/save-our-planet')
                        }
                    }}>Cancel</Btn>
                </div>
            </Form>
        </Fragment>
    );
};

export default FormPost;