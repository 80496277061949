import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { PopupCollectionDataTable } from './PopupCollectionDataTable';


const SkinAnalyzerCollectionManagement = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Table" title="Popup Collection" mainTitle="Popup Collection"/>
            <PopupCollectionDataTable/>
        </Fragment>
    );

};

export default SkinAnalyzerCollectionManagement;