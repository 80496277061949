import React, { Fragment, useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import { API_MAILING_TEMPLATES } from '../../../api/server';
import SweetAlert from 'sweetalert2';


export const MailingTemplateTypeahead = ({
                                             multiple = false,
                                             placeholder = '',
                                             onChange,
                                             defaultSelectedId = [],
                                             required = false
                                         }) => {
    const [data, setData] = useState([])

    const [defaultValue, setDefaultValue] = useState([])

    useEffect(() => {
        if (defaultSelectedId.length > 0) {
            setDefaultValue(data.find((e) => e.id === defaultSelectedId))
        } else {
            setDefaultValue([])
        }
    }, [defaultSelectedId])

    useEffect(() => {
        axios.get(API_MAILING_TEMPLATES)
            .then(r => {
                if (r.status === 200) {
                    return r.data
                }
            })
            .then(json => {
                if (json['data'] !== null) {
                    setData(json['data'])
                }
            })
            .catch(() => {
                SweetAlert.fire('Error!', 'Failed Load Templates Data from SendInBlue, Please Refresh this Page !', 'error')
            })
    }, [])

    return (
        <Fragment>
            <Typeahead
                selected={defaultValue}
                onChange={onChange}
                id="sib-templates-typeahead"
                className="mt-2"
                clearButton={false}
                labelKey="name"
                multiple={multiple}
                options={data}
                placeholder={placeholder}
            />
        </Fragment>
    )
}