import React, { Fragment } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { PostEdit } from '../../../Constant';
import { Breadcrumbs } from '../../../AbstractElements';
import FormPost from './FormPost';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';

const CreateSaveOurPlanetPost = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Add Post" parent="Blog" title="Add Post"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={PostEdit}/>
                            <CardBody className="add-post">
                                <FormPost/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default CreateSaveOurPlanetPost;