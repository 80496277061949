import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { useNavigate } from 'react-router-dom';
import { SubscriptionEmailDataTable } from './SubscriptionEmailDataTable';


const SubscriptionEmailManagement = () => {

    const [drawerState, setDrawerState] = React.useState({
        create: false,
        edit: false
    })

    const [selectedRows, setSelectedRows] = useState([]);
    const nav = useNavigate();

    const closeAllDrawer = () => {
        nav('/store/product/category-list');
        setDrawerState({
            create: false,
            edit: false,
        })
    }

    return (
        <Fragment>
            <Breadcrumbs parent="Table" title="Subscription List" mainTitle="Subscription List"/>
            <SubscriptionEmailDataTable/>
            {/*<ProductCategoryDataTable setDrawer={setDrawerState}*/}
            {/*                          drawer={drawerState}*/}
            {/*                          selectedRows={selectedRows}*/}
            {/*                          setSelectedRows={setSelectedRows}/>*/}
            {/*<Drawer*/}
            {/*    open={drawerState.edit}*/}
            {/*    onClose={closeAllDrawer}*/}
            {/*    direction="right"*/}
            {/*    className={'creation-drawer'}*/}
            {/*>*/}
            {/*    {selectedRows.map((v, i) => {*/}
            {/*        return <CreateProductCategory id={v.id}/>*/}
            {/*    })}*/}
            {/*</Drawer>*/}
            {/*<Drawer*/}
            {/*    open={drawerState.create}*/}
            {/*    onClose={closeAllDrawer}*/}
            {/*    direction="right"*/}
            {/*    className={'creation-drawer'}*/}
            {/*>*/}
            {/*    <CreateProductCategory/>*/}
            {/*</Drawer>*/}
        </Fragment>
    );

};

export default SubscriptionEmailManagement;