import React, { Fragment } from 'react';
import NavbarThemeCard from './NavbarThemeCard';
import FooterImageTheme from './FooterImageTheme';
import NewsletterThemeCard from './NewsletterTheme';

const ThemeManagement = () => {
    return (
        <Fragment>
            <NavbarThemeCard/>
            <NewsletterThemeCard/>
            <FooterImageTheme/>
        </Fragment>
    );
};

export default ThemeManagement;