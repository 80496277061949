import { PopupDisplay } from './PopupDisplay';
import { PopupConfiguration } from './PopupConfiguration';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { API_POPUP } from '../../../api/server';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import SweetAlert from 'sweetalert2';

export const CreatePopupItems = () => {
    const [ready, setReady] = useState(true)

    const [data, setData] = useState({
        name: ''
    })
    const [popupData, setPopupData] = useState({
        type: 1,
        form_attribute: {
            email: true,
            name: true,
            wa: true,
            headline: '',
            tagline: ''
        },
        background: {
            form: {
                image_position: 'left',
                background_grid_size: 6,
                background_color: '#222222'
            },
            server_id: '',
            local_url: '',
            size: 'contain'
        },
        size: {
            width: 0,
            width_suffix: 'px',
            height: 0,
            height_suffix: 'px'
        },
        button: {
            enabled: false,
            width: 0,
            width_suffix: 'px',
            text_color: '#000000',
            background_color: '#FFFFFF',
            label: '',
            link: ''
        }
    })

    const [searchParams, setSearchParams] = useSearchParams();
    const imageRef = useRef(null);

    useEffect(() => {
        if (searchParams.get('edit') !== null) {
            axios.get(`${API_POPUP}/?pagination=${CreatePaginationEncoding(1, 0)}&filter=${CreateFilterEncoding({
                id: parseInt(searchParams.get('edit'))
            })}`)
                .then((r) => {
                    if (r.status === 200) {
                        return r.data
                    }
                })
                .then((json) => {
                    if (json.data !== null && json.data.data.length > 0) {
                        setData({
                            ...data,
                            name: json.data.data[0].name
                        })
                        setPopupData({
                            ...popupData,
                            ...json.data.data[0].configuration
                        })
                    }
                })
        }
    }, [searchParams])

    const nav = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()

        setReady(false)

        imageRef.current.processFile()
            .then((f) => {
                if (searchParams.get('edit') === null) {
                    axios.post(API_POPUP + '/', {
                        name: data.name,
                        configuration: {
                            ...popupData,
                            background: {
                                ...popupData.background,
                                server_id: f.serverId
                            }
                        }
                    })
                        .then((r) => {
                            if (r.status === 204) {
                                SweetAlert.fire({
                                    title: 'Good job!',
                                    text: 'Data Saving Success!',
                                    icon: 'success'
                                })
                                    .then(() => {
                                        setReady(true)
                                    })
                                    .then(() => {
                                        nav('/content/homepage/popup');
                                    })
                            }
                        })
                        .catch(() => {
                            SweetAlert
                                .fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' })
                                .then(() => {
                                    setReady(true)
                                })
                        })
                } else {
                    axios.put(API_POPUP + '/', {
                        id: parseInt(searchParams.get('edit')),
                        name: data.name,
                        configuration: {
                            ...popupData,
                            background: {
                                ...popupData.background,
                                server_id: f.serverId
                            }
                        }
                    })
                        .then((r) => {
                            if (r.status === 204) {
                                SweetAlert
                                    .fire({
                                        title: 'Good job!',
                                        text: 'Data Saving Success!',
                                        icon: 'success'
                                    })
                                    .then(() => {
                                        setReady(true)
                                    })
                                    .then(() => {
                                        nav('/content/homepage/popup');
                                    })
                            }
                        })
                        .catch(() => {
                            SweetAlert
                                .fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' })
                                .then(() => {
                                    setReady(true)
                                })
                        })
                }
            })


    }


    return (
        <>


            <PopupConfiguration popupData={popupData} setPopupData={setPopupData} data={data} setData={setData}
                                onSubmit={handleSubmit} ready={ready} imageRef={imageRef}/>
            <PopupDisplay popupData={popupData} setPopupData={setPopupData}/>
        </>
    )
}