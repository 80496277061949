import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';

import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { ProductCategoryDataTable } from './ProductCategoryDataTable';
import CreateProductCategory from '../Create Product Category';
import { useNavigate } from 'react-router-dom';


const ProductCategoryList = () => {

    const [drawerState, setDrawerState] = React.useState({
        create: false,
        edit: false
    })

    const [selectedRows, setSelectedRows] = useState([]);
    const nav = useNavigate();

    const closeAllDrawer = () => {
        nav('/store/product/category-list');
        setDrawerState({
            create: false,
            edit: false,
        })
    }

    return (
        <Fragment>
            <Breadcrumbs parent="Product Category" title="Product Category List" mainTitle="Product Category List"/>
            <ProductCategoryDataTable setDrawer={setDrawerState}
                                      drawer={drawerState}
                                      selectedRows={selectedRows}
                                      setSelectedRows={setSelectedRows}/>
            <Drawer
                open={drawerState.edit}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                {selectedRows.map((v, i) => {
                    return <CreateProductCategory id={v.id}/>
                })}
            </Drawer>
            <Drawer
                open={drawerState.create}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <CreateProductCategory/>
            </Drawer>
        </Fragment>
    );

};

export default ProductCategoryList;