import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import React from 'react';
import { PopupWithFormAdjustment } from './PopupWithFormAdjusment';
import { PopupWithoutFormAdjustment } from './PopupWithoutFormAdjustment';

export const PopupConfiguration = ({ popupData, setPopupData, data, setData, onSubmit, ready = true, imageRef }) => {
    const popupTypeOption = [
        {
            value: 1,
            label: 'Without Form'
        },
        {
            value: 2,
            label: 'With Form'
        }
    ]


    return (
        <>
            <Card>
                <CardHeader>
                    <H5>Configuration</H5>
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input className="form-control" type="text"
                               value={data.name}
                               onChange={e => {
                                   setData({
                                       ...data,
                                       name: e.target.value
                                   })
                               }}
                               placeholder="ABCDEF"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Type</Label>
                        <Input type="select"
                               name="popup-type"
                               className="form-control digits"
                               defaultValue={popupData.type}
                               onChange={(e) => {
                                   setPopupData({
                                       ...popupData,
                                       type: parseInt(e.target.value)
                                   })
                               }}>
                            {
                                popupTypeOption.map((v) => {
                                    return <option key={v.label} value={v.value}>{v.label}</option>
                                })
                            }
                        </Input>
                    </FormGroup>

                    {
                        popupData.type === 1 ?
                            <PopupWithoutFormAdjustment popupData={popupData} setPopupData={setPopupData}
                                                        imageRef={imageRef}/> :
                            <PopupWithFormAdjustment popupData={popupData} setPopupData={setPopupData}
                                                     imageRef={imageRef}/>
                    }

                    {/*<PopupWithFormAdjustment/>*/}
                    <Row className="justify-content-end" style={{
                        marginTop: '35px'
                    }}>
                        <Col md={4}>
                            <Btn attrBtn={{
                                color: 'primary',
                                type: 'button',
                                style: {
                                    width: '100%'
                                },
                                disabled: !ready,
                                onClick: (e) => {
                                    if (ready) {
                                        onSubmit(e)
                                    }
                                }
                            }}>{ready ? 'Submit' : 'Loading ...'}</Btn>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}