import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Container, Form, FormGroup, Input, Label, Media, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import { PopupTypeahead } from '../CreatePopupItems/PopupTypeahead';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';

const ActivePopupManagement = () => {
    const [data, setData] = useState({
        active: false,
        block_content: false,
        popup_desktop_id: 0,
        popup_mobile_id: 0,
    })

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/HP_POPUP').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })

        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])

    const handlerSubmit = (e) => {
        e.preventDefault();

        axios.post(API_CONFIGURATOR + '/', {
            key: 'HP_POPUP',
            value: JSON.stringify({ ...data })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Card>
                    <HeaderCard title="Popup Management"/>
                    <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <Media>
                                        <Label className="col-form-label m-r-35">Active ?</Label>
                                        <Media body className="text-start icon-state switch-lg">
                                            <Label className="switch">
                                                <Input type="checkbox"
                                                       checked={data.active}
                                                       onChange={() => {
                                                           setData({
                                                               ...data,
                                                               active: !data.active
                                                           })
                                                       }}
                                                />
                                                <span className="switch-state bg-primary"/>
                                            </Label>
                                        </Media>
                                    </Media>
                                </Col>
                                <Col md={6}>
                                    <Media>
                                        <Label className="col-form-label m-r-35">Block Content ?</Label>
                                        <Media body className="text-start icon-state switch-lg">
                                            <Label className="switch">
                                                <Input type="checkbox"
                                                       checked={data.block_content}
                                                       onChange={() => {
                                                           setData({
                                                               ...data,
                                                               block_content: !data.block_content
                                                           })
                                                       }}
                                                />
                                                <span className="switch-state bg-primary"/>
                                            </Label>
                                        </Media>
                                    </Media>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="col-form-label m-r-35">Desktop Popup</Label>
                                        <PopupTypeahead defaultSelectedId={[data.popup_desktop_id]}
                                                        onChange={(opt) => {
                                                            if (opt.length > 0) {
                                                                setData({
                                                                    ...data,
                                                                    popup_desktop_id: opt[0].id
                                                                })
                                                            }

                                                        }}/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="col-form-label m-r-35">Mobile Popup</Label>
                                        <PopupTypeahead defaultSelectedId={[data.popup_mobile_id]} onChange={(opt) => {
                                            console.log(opt)


                                            if (opt.length > 0) {
                                                console.log({
                                                    ...data,
                                                    popup_mobile_id: opt[0].id || 0
                                                })
                                                setData({
                                                    ...data,
                                                    popup_mobile_id: opt[0].id
                                                })
                                            }
                                        }}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="text-end">
                            <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                            <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                        </CardFooter>
                    </Form>
                </Card>

            </Container>
        </Fragment>
    );
};

export default ActivePopupManagement;