import React, { Fragment, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export const PartnersTypeTypeahead = ({ multiple = false, placeholder = '', onChange, defaultSelectedId = [] }) => {
    // ['Stockist', 'Reseller', 'Offline Store']
    const [data, setData] = useState([
        {
            id: 1,
            name: 'Stockist'
        },
        {
            id: 2,
            name: 'Reseller'
        },
        {
            id: 3,
            name: 'Offline Store'
        },
        {
            id: 4,
            name: 'Agen'
        }
    ])
    const [initialData, setInitialData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [wait, setWait] = useState(false)


    // useEffect(() => {
    //     axios.get(API_BLOG_CATEGORY + '/?pagination=' + CreatePaginationEncoding(5, 0)).then(r => {
    //         if (r.status === 200) {
    //             return r.data
    //         }
    //     }).then(json => {
    //         if (json['data']['data'] !== null) {
    //             setData(json['data']['data'])
    //             setInitialData(json['data']['data'])
    //         }
    //     }).catch(() => {
    //         SweetAlert.fire('Error!', 'Data Load Failed', 'error')
    //     })
    // }, [])

    // useEffect(() => {
    //     // setWait(true);
    //     // if (!wait) {
    //     if (searchTerm !== '') {
    //         axios.get(API_BLOG_CATEGORY + '/?pagination=' + CreatePaginationEncoding(5, 0) + '&filter=' + CreateFilterEncoding({ name: searchTerm })).then(r => {
    //             if (r.status === 200) {
    //                 return r.data
    //             }
    //         }).then(json => {
    //             if (json['data'] !== null) {
    //                 setData(json['data']['data'])
    //             } else {
    //                 setData([])
    //             }
    //             setWait(false);
    //         }).catch(() => {
    //             setData([])
    //         })
    //     } else {
    //         setData(initialData)
    //     }
    //     // }
    // }, [searchTerm])

    return (
        <Fragment>
            <Typeahead
                // onInputChange={(t, e) => {
                //     setSearchTerm(t)
                // }}
                allowNew={false}
                onChange={onChange}
                id="blog-category-typeahead"
                className="mt-2"
                clearButton={false}
                labelKey="name"
                multiple={multiple}
                options={data}
                placeholder={placeholder}
            />
        </Fragment>
    )
}