import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';

const ContactInfoManagement = () => {
    const [data, setData] = useState({
        address: '',
        email: '',
        phone: '',
        working_hours: ''
    })

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_ABOUT_US_INFO').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])


    const handlerSubmit = (e) => {
        e.preventDefault();

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_ABOUT_US_INFO',
            value: JSON.stringify(data)
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }


    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage Concact Info"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Address</Label>
                                    <Input className="form-control" type="text"
                                           value={data.address}
                                           onChange={e => setData({ ...data, address: e.target.value })}
                                           placeholder="ABCDEF"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Phone</Label>
                                    <Input className="form-control" type="text"
                                           value={data.phone}
                                           onChange={e => setData({ ...data, phone: e.target.value })}
                                           placeholder="ABCDEF"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Email</Label>
                                    <Input className="form-control" type="text"
                                           value={data.email}
                                           onChange={e => setData({ ...data, email: e.target.value })}
                                           placeholder="ABCDEF"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Working Hours</Label>
                                    <Input className="form-control" type="text"
                                           value={data.working_hours}
                                           onChange={e => setData({ ...data, working_hours: e.target.value })}
                                           placeholder="ABCDEF"/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        {/*<Btn attrBtn={{ color: 'light' }}>Cancel</Btn>*/}
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default ContactInfoManagement;