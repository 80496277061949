import React, { Fragment, useEffect, useState } from 'react';
import { Btn, H4, H6 } from '../../../AbstractElements';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import axios from 'axios';
import { API_USER_LOGIN } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { LS_USER_KEY } from '../../../Utils/jwt';
import {useCookies} from 'react-cookie';

const LoginPages = () => {
    const [showPassword, setShowPassword] = useState(false)

    const [cookies, setCookie, removeCookie] = useCookies(['XJ-AUTH']);

    const [form, setForms] = useState({
        username: '',
        password: ''
    })

    const nav = useNavigate()

    useEffect(() => {
        const _authKey = localStorage.getItem(LS_USER_KEY)
        if (_authKey !== null && _authKey !== '') {
            nav('/content/homepage/carousel');
        }
    }, [])

    const handleLogin = (e) => {
        e.preventDefault()
        axios.post(API_USER_LOGIN, {
            ...form
        }).then(r => {
            if (r.status === 200) {
                SweetAlert.fire({ title: 'Good job!', text: 'Login Success!', icon: 'success' }).then(() => {
                    localStorage.setItem(LS_USER_KEY, r.data['data']['token']);
                    setCookie("XJ-AUTH", r.data['data']['token'], {path: '/'})
                }).then(() => {
                    // console.log("LOG", r.data.data.token)
                    nav('/content/homepage/carousel');
                })
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Login Failed!',
                text: 'Usename / Password is Wrong',
                icon: 'error'
            }).then(() => {
            })
        })
    }

    return (
        <Fragment>
            <div className="login-card">
                <Form className="theme-form login-form" onSubmit={handleLogin}>
                    <H4>Login</H4>
                    <H6>Welcome back! Log in to your account.</H6>
                    <FormGroup>
                        <Label>Email Address</Label>
                        <div className="input-group"><span className="input-group-text"><i
                            className="icon-user"></i></span>
                            <Input onChange={(e) => setForms({ ...form, username: e.target.value })}
                                   className="form-control" type="text" required="" placeholder="username"/>
                        </div>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label>Password</Label>
                        <div className="input-group"><span className="input-group-text"><i
                            className="icon-lock"></i></span>
                            <Input onChange={(e) => setForms({ ...form, password: e.target.value })}
                                   className="form-control" type={showPassword ? 'text' : 'password'} required={true}
                                   placeholder="*********"/>
                            <div className="show-hide" onClick={() => {
                                setShowPassword(!showPassword)
                            }}><span className={showPassword ? 'hide' : 'show'}/></div>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className="checkbox">
                            <Input id="checkbox1" type="checkbox"/>
                            <Label className="text-muted" for="checkbox1">Remember password</Label>
                        </div>
                        {/*    <a className="link" href="forget-password.html">Forgot password?</a>*/}
                    </FormGroup>
                    <FormGroup>
                        <Btn attrBtn={{ className: 'btn-block', color: 'primary', type: 'submit' }}>Sign in</Btn>
                    </FormGroup>
                    {/*<div className="login-social-title">*/}
                    {/*    <H5>Sign in with</H5>*/}
                    {/*</div>*/}
                    {/*<FormGroup>*/}
                    {/*    <UL attrUL={{ as: 'ul', className: 'login-social flex-row' }}>*/}
                    {/*        <LI><a href="https://www.linkedin.com/login"><Linkedin/></a></LI>*/}
                    {/*        <LI><a href="https://www.linkedin.com/login"><Twitter/></a></LI>*/}
                    {/*        <LI><a href="https://www.linkedin.com/login"><Facebook/></a></LI>*/}
                    {/*        <LI><a href="https://www.instagram.com/login"><Instagram/></a></LI>*/}
                    {/*    </UL>*/}
                    {/*</FormGroup>*/}
                    {/*<P>Don't have account?<a className="ms-2" href="sign-up.html">Create Account</a></P>*/}
                </Form>
            </div>
        </Fragment>
    );
};

export default LoginPages;