import {Col, Container, Row} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import {useEffect, useState} from "react";
import MyProfileEdit from "./MyProfile";
import axios from "axios";
import { API_USER_CURRENT } from '../../api/server';

const ManageAccountPages = () => {
    const [accountData, setAccountData] = useState(
        {
            id: 0,
            full_name: "",
            login_username: "",
            login_password: "",
            role: 0
        }
    )

    useEffect(() => {
        axios.get(API_USER_CURRENT, {withCredentials: true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            const serverAccountData = json.data.data[0]
            console.log("CURRENT", json.data.data[0])
            setAccountData({
                ...accountData,
                ...serverAccountData
            })
        })
    }, [])


    return (
        <>
            <Breadcrumbs mainTitle="User Edit" parent="Users" title="User Edit"/>
            <Container fluid={true}>
                <div className="edit-profile">
                    <Row>
                        <Col xl="12">
                            <MyProfileEdit accountData={accountData} setAccountData={setAccountData}/>
                        </Col>
                        {/*<Col xl="8">*/}
                        {/*    <EditMyProfile accountData={accountData} setAccountData={setAccountData}*/}
                        {/*                   filesPhoto={filesPhoto} setFilesPhoto={setFilesPhoto}*/}
                        {/*                   filesCV={filesCV} setFilesCV={setFilesCV}*/}
                        {/*                   filesEducationCertificate={filesEducationCertificate}*/}
                        {/*                   setFilesEducationCertificate={setFilesEducationCertificate}/>*/}
                        {/*</Col>*/}
                    </Row>
                </div>
            </Container>
        </>
    )
}

export default ManageAccountPages