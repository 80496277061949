import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import SCImageUploader from './upload_sc_image';
import { ProductTypeahead } from '../../../Components/Common/Component/ProductTypeahead';
import axios from 'axios';
import { API_SKIN_CONCERN } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { defaultData } from './const';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';
import { useNavigate } from 'react-router-dom';


const SkinConcernForm = ({ editData = null, allData, setAddTask, setParentData }) => {
    const closeTaskWrapper = () => {
        setAddTask('');
        document.getElementById('addTaskBtn').classList.remove('hide');
    };

    const [data, setData] = useState(defaultData)
    const [image, setImage] = useState([])
    const [editorData, setEditorData] = useState('')

    const nav = useNavigate();

    useEffect(() => {
        if (editData !== null && editData !== {}) {
            setData(editData)
            if (editData.content !== undefined && editData.content !== null) {
                setEditorData(editData.content)
            } else {
                setEditorData('')
            }
        } else {
            setData(defaultData)
            setEditorData('')
        }
    }, [editData])

    useEffect(() => {
        if (data.image_id !== '') {
            setImage([{
                source: data.image_id,
                options: { type: 'local' }
            }])
        } else {
            setImage([])
        }
    }, [data.image_id])

    const handlerOnSubmit = (e) => {
        e.preventDefault()

        let img_id = ''

        if (image.length > 0) {
            img_id = image[0].serverId
        } else {
            img_id = data.image_id
        }

        const allDataProcessed = editData !== null ? allData.filter((v, i, a) => {
            return v.timestamp !== editData.timestamp
        }) : allData

        // const ts = window.performance.now();

        if (editData === null) {
            axios.post(API_SKIN_CONCERN + '/', {
                ...data,
                image_id: img_id,
                content: editorData
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
                }
            }).catch(() => {
                SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
            }).then(() => {
                setData(defaultData)
                setEditorData('')
                closeTaskWrapper()
                nav('/content/skin/concern')
            })
        } else {
            axios.put(API_SKIN_CONCERN + '/', {
                ...data,
                id: editData.id,
                image_id: img_id,
                content: editorData
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
                }
            }).catch(() => {
                SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
            }).then(() => {
                setData(defaultData)
                setEditorData('')
                closeTaskWrapper()
                nav('/content/skin/concern')
            })
        }


    }

    return (
        <Fragment>
            <Form onSubmit={handlerOnSubmit}>
                <Row>
                    <Col md={4} sm={12} lg={4}>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Image (600 x 360)</Label>
                            <SCImageUploader image={image} setImage={setImage}/>
                        </FormGroup>
                    </Col>
                    <Col md={8} sm={12} lg={8}>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Title</Label>
                            <Input
                                value={data.title}
                                onChange={(e) => {
                                    setData({ ...data, title: e.target.value })
                                }}
                                className="form-control" type="text" placeholder="This is my subtitle"/>
                        </FormGroup>
                        <FormGroup>
                            <div className="col-form-Label">Related Products:
                                <ProductTypeahead multiple={true}
                                                  placeholder={'Related Products List'}
                                                  onChange={(option) => {
                                                      setData({
                                                          ...data,
                                                          products: option.map((v, i) => v.id)
                                                      })
                                                  }}
                                                  defaultSelectedId={data.products}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <div className="col-form-Label">Text Content:
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={editorData}
                                    config={{
                                        extraPlugins: [MyCustomUploadAdapterPlugin],
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setEditorData(data)
                                    }}
                                />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={12} lg={12} sm={12} style={{ margin: '16px 0' }}>
                        <Btn attrBtn={{
                            color: 'danger',
                            className: 'cancel-btn',
                            id: 'close-task-panel',
                            onClick: closeTaskWrapper
                        }}>Cancel</Btn>
                        <Btn attrBtn={{
                            color: 'success',
                            className: 'ms-3 add-new-task-btn',
                            id: 'add-task',
                            type: 'submit',
                        }}>
                            Save
                        </Btn>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )

}

export default SkinConcernForm