import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { value } from 'lodash/seq';
import { defaultData } from './data';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';


const FAQForm = ({ editData, allData, setAddTask, setParentData }) => {
    const closeTaskWrapper = () => {
        setAddTask('');
        document.getElementById('addTaskBtn').classList.remove('hide');
    };

    const [data, setData] = useState(defaultData)

    useEffect(() => {
        if (editData !== null && editData !== {}) {
            setData(editData)
        } else {
            setData(defaultData)
        }
    }, [editData])

    const handlerOnSubmit = (e) => {
        e.preventDefault()

        const allDataProcessed = editData !== null ? allData.filter((v, i, a) => {
            return v.timestamp !== editData.timestamp
        }) : allData

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_FAQ',
            value: JSON.stringify({
                data: [
                    ...allDataProcessed,
                    {
                        ...data,
                        timestamp: window.performance.now()
                    }
                ]
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        }).then(() => {
            setData(defaultData)
            closeTaskWrapper()
            setParentData([
                ...allDataProcessed,
                {
                    ...data,
                    timestamp: window.performance.now()
                }
            ])
        })

    }

    return (
        <Fragment>
            <Form onSubmit={handlerOnSubmit}>
                <Row>
                    <Col md={12} sm={12} lg={12}>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Order</Label>
                            <Input
                                value={data.order}
                                onChange={(e) => {
                                    setData({ ...data, order: parseInt(e.target.value) })
                                }}
                                className="form-control" type="number" placeholder="5"/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Title</Label>
                            <Input
                                value={data.question}
                                onChange={(e) => {
                                    setData({ ...data, question: e.target.value })
                                }}
                                className="form-control" type="text" placeholder="ABCDEFGHIJKLMNOPQRSTUVWXYZ"/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Content</Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={data.answer}
                                config={{
                                    extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                                onChange={(event, editor) => {
                                    const e = editor.getData();
                                    // setEditorData(data)
                                    setData({ ...data, answer: e })
                                }}
                            />
                            {/*<Input*/}
                            {/*    value={data.answer}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        setData({ ...data, answer: e.target.value })*/}
                            {/*    }}*/}
                            {/*    className="form-control" type="textarea" placeholder="ABCDEFGHIJKLMNOPQRSTUVWXYZ"/>*/}
                        </FormGroup>
                    </Col>


                    <Col md={12} lg={12} sm={12} style={{ margin: '16px 0' }}>
                        <Btn attrBtn={{
                            color: 'danger',
                            className: 'cancel-btn',
                            id: 'close-task-panel',
                            onClick: closeTaskWrapper
                        }}>Cancel</Btn>
                        <Btn attrBtn={{
                            color: 'success',
                            className: 'ms-3 add-new-task-btn',
                            id: 'add-task',
                            type: 'submit',
                        }}>
                            Save
                        </Btn>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )

}

export default FAQForm