import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import CreateSkinAnalyzerQuestionForms from './forms';

const CreateSkinAnalyzerQuestion = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Skin Analyzer Management" parent="Homepage" title="Carousel Management"/>
            <Container fluid={true}>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <H5>Skin Analyzer Question Management</H5>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <CreateSkinAnalyzerQuestionForms/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default CreateSkinAnalyzerQuestion;