import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { API_POPUP } from '../../../api/server';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import SweetAlert from 'sweetalert2';
import { Typeahead } from 'react-bootstrap-typeahead';

export const PopupTypeahead = ({ multiple = false, placeholder = '', onChange, defaultSelectedId = [] }) => {
    const [data, setData] = useState([])
    const [initialData, setInitialData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [wait, setWait] = useState(false)

    const [selected, setSelected] = useState([])


    useEffect(() => {
        axios.get(API_POPUP + '/?pagination=' + CreatePaginationEncoding(5, 0)).then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            if (json['data']['data'] !== null) {
                setData(json['data']['data'])
                setInitialData(json['data']['data'])
            }
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])

    useEffect(() => {
        if (defaultSelectedId.length > 0) {
            axios.get(API_POPUP + '/?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({
                id: defaultSelectedId[0]
            })).then(r => {
                if (r.status === 200) {
                    return r.data
                }
            }).then(json => {
                if (json['data']['data'] !== null) {
                    setSelected(json['data']['data'])
                }
            }).catch(() => {
                SweetAlert.fire('Error!', 'Data Load Failed', 'error')
            })
        }

    }, [defaultSelectedId])

    useEffect(() => {
        if (searchTerm !== '') {
            axios.get(API_POPUP + '/?pagination=' + CreatePaginationEncoding(5, 0) + '&filter=' + CreateFilterEncoding({ name: searchTerm })).then(r => {
                if (r.status === 200) {
                    return r.data
                }
            }).then(json => {
                if (json['data'] !== null) {
                    setData(json['data']['data'])
                } else {
                    setData([])
                }
            }).catch(() => {
                setData([])
            })
        } else {
            setData(initialData)
        }
    }, [searchTerm])

    return (
        <Fragment>
            <Typeahead
                onInputChange={(t, e) => {
                    setSearchTerm(t)
                }}
                selected={selected}
                allowNew={false}
                onChange={(opt) => {
                    setSelected(opt)
                    onChange(opt)
                }}
                id="popup-typeahead"
                className="mt-2"
                clearButton={false}
                labelKey="name"
                multiple={multiple}
                options={data ? data : []}
                placeholder={placeholder}
            />
        </Fragment>
    )
}