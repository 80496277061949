import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import CollabManagementForms from './Forms';

export const CreateCollabManagement = () => {
    const [selectedRows, setSelectedRows] = useState([]);

    return (
        <Fragment>
            <Breadcrumbs parent="Promotion" title="Collaboration" mainTitle="Collaboration Management"/>
            <CollabManagementForms/>
        </Fragment>
    )

}