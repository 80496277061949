import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import DataTable from 'react-data-table-component';
import {
    API_CONFIGURATOR,
    API_SKIN_ANALYZER_COLLECTION,
    API_SKIN_ANALYZER_COLLECTION_EXPORT
} from '../../../api/server';
import { CreatePaginationEncoding } from '../../../Utils/Base64/request';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { MailingTemplateTypeahead } from '../../../Components/Common/Component/MailingTemplateTypeahead';
import { Btn } from '../../../AbstractElements';

export const SkinAnalyzerCollectionDataTable = ({ drawer, setDrawer, setSelectedRows, selectedRows }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get(API_SKIN_ANALYZER_COLLECTION + '/?pagination=' + CreatePaginationEncoding(100, 0)).then(r => {
            if (r.status === 200) {
                return r.data['data'];
            }
        }).then(json => {
            console.log(json['data'])
            json['data'] && setData(json['data'])
        }).catch(() => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
            })
        })
    }, [])

    const tableColumns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
            center: true,
        },
        {
            name: 'Age',
            selector: (row) => row.age,
            sortable: true,
            center: true,
        },
        {
            name: 'Gender',
            selector: (row) => ['', 'Male', 'Female'][row.gender],
            sortable: true,
            center: true,
        },
    ];

    const [templateData, setTemplateData] = useState(0)

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_MTP_SAC',
            value: JSON.stringify({
                data: {
                    template_id: templateData
                }
            })
        })
            .then(r => {
                if (r.status === 204) {
                    SweetAlert.fire('Good Job!', 'Data Saving Success', 'success').then()
                }
            })
            .catch(() => {
                SweetAlert.fire('Failed!', 'Data Saving Failed', 'error').then()
            })
    }

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_MTP_SAC').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setTemplateData(json.data.template_id)
            console.log(json.data.template_id)
        })
    }, [])

    const handleExport = (e) => {
        e.preventDefault()
        window.open(API_SKIN_ANALYZER_COLLECTION_EXPORT, '_blank')
    }

    return (
        <Fragment>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <Col md={12}>
                                            <Form onSubmit={handleSubmit}>
                                                <Row className="justify-content-between">
                                                    <Col md="auto">
                                                        <Row className="justify-content-start align-items-end">
                                                            <Col md="auto">
                                                                <FormGroup className="row">
                                                                    <Label htmlFor="exampleFormControlSelect3">Mailing
                                                                        Template</Label>
                                                                    <Col sm="12">
                                                                        <MailingTemplateTypeahead multiple={false}
                                                                                                  placeholder={'Please Select Templates'}
                                                                                                  defaultSelectedId={[templateData]}
                                                                                                  onChange={(options) => {
                                                                                                      options.length > 0 && setTemplateData(options[0].id)
                                                                                                  }}/>
                                                                    </Col>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="auto">
                                                                <Label htmlFor="exampleFormControlSelect3"></Label>
                                                                <FormGroup className="row">
                                                                    <Btn attrBtn={{
                                                                        color: 'primary',
                                                                        className: 'm-r-15',
                                                                        type: 'submit'
                                                                    }}>Save Template</Btn>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Col md="auto">
                                                            <Label htmlFor="exampleFormControlSelect3"></Label>
                                                            <FormGroup className="row">
                                                                <Btn attrBtn={{
                                                                    color: 'primary',
                                                                    className: 'm-r-15',
                                                                    type: 'button',
                                                                    onClick: handleExport
                                                                }}>Export CSV</Btn>
                                                            </FormGroup>
                                                        </Col>
                                                    </Col>
                                                </Row>


                                                <Label htmlFor="exampleFormControlSelect3">Selected Template ID :
                                                    #{templateData}</Label>
                                            </Form>
                                        </Col>
                                    </Col>
                                    <Col md={12}>
                                        <DataTable
                                            data={data}
                                            columns={tableColumns}
                                            striped={true}
                                            center={true}
                                            selectableRows
                                            persistTableHead
                                            // onSelectedRowsChange={handleRowSelected}
                                            // clearSelectedRows={toggleCleared}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}