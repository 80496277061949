import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import TodoList from './TodoList';

const SkinConcernManagement = () => {

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Skin Concern Management" parent="Skin" title="Skin Concern Management"/>
            <Container fluid={true}>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <H5>Skin Concern Management</H5>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <div className="todo">
                                    <div className="todo-list-wrapper">
                                        <div className="todo-list-container">
                                            {/*<CarouselCheckbox />*/}
                                            <TodoList/>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default SkinConcernManagement;