import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { API_CDN_CNF, API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import { FilePond } from 'react-filepond';
import { HexColorPicker } from 'react-colorful';

const NavbarThemeCard = () => {
    const [data, setData] = useState({
        image_id: '',
        logo_image_id: '',
        background_color: '',
        text_color: '',
        collab_text_color: ''
    })


    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_THEME_NAVBAR').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })
            if (json.image_id && json.image_id !== '') {
                setMainImage([{
                    source: json.image_id,
                    options: { type: 'local' }
                }])
            }
            if (json.logo_image_id && json.logo_image_id !== '') {
                setLogoImage([{
                    source: json.logo_image_id,
                    options: { type: 'local' }
                }])
            }

        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])


    const handlerSubmit = (e) => {
        e.preventDefault();

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_THEME_NAVBAR',
            value: JSON.stringify({
                ...data,
                image_id: mainImage[0] ? mainImage[0].serverId : '',
                logo_image_id: logoImage[0] ? logoImage[0].serverId : ''
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }

    const handlerReset = () => {

        SweetAlert.fire({
            title: 'Continue Reset?',
            html: 'This Action Cannot be undone',
            icon: 'warning',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Reset',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.post(API_CONFIGURATOR + '/', {
                    key: 'CF_THEME_NAVBAR',
                    value: JSON.stringify({
                        background_color: '#FFFFFF',
                        text_color: '#111111',
                        collab_text_color: '#C9893B',
                        image_id: '',
                        logo_image_id: ''
                    })
                }).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
                        setData({
                            background_color: '#FFFFFF',
                            text_color: '#111111',
                            collab_text_color: '#C9893B',
                            image_id: '',
                            logo_image_id: ''
                        })
                        setMainImage([])
                        setLogoImage([])
                    }
                }).catch(() => {
                    SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
                })
            }
        });


    }


    const [mainImage, setMainImage] = useState([])
    const [logoImage, setLogoImage] = useState([]);
    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage Navbar Theme"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Background Photo (1900 X 100)</Label>
                                    <FilePond
                                        // required
                                        allowMultiple={false}
                                        allowImageCrop={true}
                                        allowImageTransform={true}
                                        allowFileSizeValidation={true}
                                        imageResizeTargetHeight={100}
                                        files={mainImage}
                                        onupdatefiles={setMainImage}
                                        maxFiles={1}
                                        imageCropAspectRatio={'1900:100'}
                                        acceptedFileTypes={['image/*']}
                                        maxFileSize={'1MB'}
                                        server={API_CDN_CNF}
                                        name="files"
                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Logo (145 X 40)</Label>
                                    <FilePond
                                        // required
                                        allowMultiple={false}
                                        allowImageCrop={true}
                                        allowImageTransform={true}
                                        allowFileSizeValidation={true}
                                        imageResizeTargetHeight={40}
                                        files={logoImage}
                                        onupdatefiles={setLogoImage}
                                        maxFiles={1}
                                        imageCropAspectRatio={'145:40'}
                                        acceptedFileTypes={['image/*']}
                                        maxFileSize={'1MB'}
                                        server={API_CDN_CNF}
                                        name="files"
                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                                    />
                                </FormGroup>
                                <Row className="justify-content-center">
                                    <Col md={'auto'}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">Background Color</Label>
                                            <HexColorPicker color={data.background_color} onChange={(v) => {
                                                setData({
                                                    ...data,
                                                    background_color: v
                                                })
                                            }}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={'auto'}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">Font Color</Label>
                                            <HexColorPicker color={data.text_color} onChange={(v) => {
                                                setData({
                                                    ...data,
                                                    text_color: v
                                                })
                                            }}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={'auto'}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">Collab Font Color</Label>
                                            <HexColorPicker color={data.collab_text_color} onChange={(v) => {
                                                setData({
                                                    ...data,
                                                    collab_text_color: v
                                                })
                                            }}/>
                                        </FormGroup>
                                    </Col>
                                </Row>


                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        <Btn attrBtn={{
                            color: 'secondary', className: 'm-r-15', onClick: () => {
                                handlerReset()
                            }
                        }}>Reset</Btn>
                        {/*<Btn attrBtn={{ color: 'light' }}>Cancel</Btn>*/}
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default NavbarThemeCard;