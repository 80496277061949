// export const API_HOST = 'http://localhost:4477'
// export const API_HOST = 'https://demo3.zukff.com'
// export const API_HOST = 'https://api.v2.ebrightskin.id'
// export const API_HOST = 'http://localhost:4477'
export const API_HOST = 'https://api.webesegas1902.ebrightskin.id'


export const API_ADMIN = API_HOST + '/admin'
export const API_CDN = API_HOST + '/cdn'

export const API_CONFIGURATOR = API_HOST + '/configurator'

export const API_CDN_TEMP = API_CDN + '/temp'
export const API_CDN_CNF = API_CDN + '/configurator'

export const API_CDN_PRODUCT = API_CDN + '/product'
export const API_CDN_PRODUCT_SERVE = API_CDN_PRODUCT + '/serve'

export const API_STORE_PRODUCT = API_ADMIN + '/product'

export const API_STORE_PARTNERS = API_ADMIN + '/store'

export const API_BLOG = API_ADMIN + '/blog'

export const API_COLLABORATION = API_ADMIN + '/collaboration'

export const API_SKIN_CONCERN = API_ADMIN + '/skin-concern'

export const API_USER = API_ADMIN + '/user'

export const API_USER_LOGIN = API_USER + '/authorize'

export const API_USER_CURRENT = API_USER + '/current'

export const API_POPUP = API_ADMIN + '/popup'

export const API_POPUP_COLLECTION = API_ADMIN + '/popup-collection'

export const API_POPUP_COLLECTION_EXPORT = API_ADMIN + '/popup-collection/export'

export const API_ADDRESS = API_ADMIN + '/address'

export const API_ADDRESS_PROVINCE = API_ADDRESS + '/province'

export const API_ADDRESS_REGENCY = API_ADDRESS + '/regency'

export const API_MAILING = API_ADMIN + '/mailing'

export const API_MAILING_TEMPLATES = API_MAILING + '/templates'

export const API_NEWSLETTER = API_ADMIN + '/newsletter'

export const API_NEWSLETTER_EXPORT = API_NEWSLETTER + '/export'

export const API_SKIN_ANALYZER_COLLECTION = API_ADMIN + '/skin-analyzer-collection'

export const API_SKIN_ANALYZER_COLLECTION_EXPORT = API_SKIN_ANALYZER_COLLECTION + '/export'

export const API_CONTACT_US = API_ADMIN + '/contact-us'

export const API_SKIN_ANALYZER = API_ADMIN + '/skin-analyzer'

export const API_SKIN_ANALYZER_QUESTION = API_SKIN_ANALYZER + '/question'

export const API_BLOG_CATEGORY = API_ADMIN + '/blog-category'

export const API_STORE_PRODUCT_CATEGORY = API_ADMIN + '/product-category'
