import React, { Fragment } from 'react';
import ContactInfoManagement from './ContactInfoManagement';
import CompanyStoryManagement from './CompanyStoryManagement';
import TimelineManagement from './TimelineManagement';
import SocialMediaManagement from './SocialMediaManagement';
import { TextSectionManagement } from './TextSectionManagement';

const AboutUsManagement = () => {
    return (
        <Fragment>
            <ContactInfoManagement/>
            <SocialMediaManagement/>
            <CompanyStoryManagement/>
            <TimelineManagement/>
            <TextSectionManagement/>
        </Fragment>
    );
};

export default AboutUsManagement;