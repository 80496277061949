export class ConfigDB {
    static data = {
        settings: {
            layout_type: 'ltr',
            sidebar: {
                type: 'compact-wrapper',
            },
            sidebar_setting: '',
        },
        color: {
            primary_color: '#c6883e',
            secondary_color: '#6e610d',
            mix_background_layout: 'light-only',
        },
        router_animation: 'fadeIn'
    }
}

export default ConfigDB;