import React from 'react'

import { FilePond } from 'react-filepond'
import { API_CDN_CNF } from '../../../api/server';


function TestimonialImageUploader({ image, setImage }) {
    return (
        <>
            <FilePond
                required
                files={image}
                allowMultiple={false}
                onupdatefiles={setImage}
                maxFiles={1}
                imageCropAspectRatio={'1:1'}
                acceptedFileTypes={['image/*']}
                allowFileSizeValidation={true}
                maxFileSize={'2MB'}
                server={API_CDN_CNF}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
            />
        </>

    )
}


export default TestimonialImageUploader
