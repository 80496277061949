import React, { Fragment } from 'react';
import CreateProductForms from './forms';


const CreateProduct = () => {
    return (
        <Fragment>
            <CreateProductForms/>
        </Fragment>
    )
}

export default CreateProduct