import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Card, FormGroup } from 'react-bootstrap';
import {
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Media,
    Row
} from 'reactstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { Btn } from '../../../AbstractElements';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { API_STORE_PRODUCT, API_STORE_PRODUCT_CATEGORY } from '../../../api/server'
import { Typeahead } from 'react-bootstrap-typeahead';
import MainImageUploader from './upload_main_image';
import OthersImageUploader from './upload_others_image';
import LoaderButton from '../../../Components/Common/Button/LoaderButton';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';

const CreateProductForms = () => {
    // Main Data
    const [data, setData] = useState({
        id: 0,
        name: '',
        sku: '',
        bpom_number: '',
        halal_number: '',
        description: '',
        how_to_apply: '',
        ingredients: '',
        price: 0,
        selling_price: 0,
        wa_number: 0,
        wa_text: '',
        categories: [],
        active: true,
        images: {
            main_photo_session: '',
            other_photo_session: [],
        },
    })

    const [editorDataDescription, setEditorDataDescription] = useState('')
    const [editorDataHTA, setEditorDataHTA] = useState('')
    const [editorDataIngredients, setEditorDataIngredients] = useState('')

    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (searchParams.get('edit') !== null) {
            axios.get(API_STORE_PRODUCT + '/?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({ id: [parseInt(searchParams.get('edit'))] })).then(r => {
                if (r.status === 200) {
                    return r.data['data'];
                }
            }).then(json => {
                setData(json['data'][0])
                setMainImage([{
                    source: json['data'][0]['images']['main_photo_session'],
                    options: { type: 'local' }
                }])
                setOtherImage([
                    ...json['data'][0]['images']['other_photo_session'].map((v, i) => {
                        return {
                            source: v,
                            options: { type: 'local' }
                        }
                    })
                ])

                setEditorDataDescription(json['data'][0].description)
                setEditorDataHTA(json['data'][0].how_to_apply)
                setEditorDataIngredients(json['data'][0].ingredients)

            }).catch(() => {
                SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                })
            })
        }
    }, [])


    const [isSubmitting, setIsSubmitting] = useState(false)

    const handlerSetFormString = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handlerSetFormNumeric = (e) => {
        setData({
            ...data,
            [e.target.name]: parseInt(e.target.value)
        })
    }

    const nav = useNavigate();

    const handlerSubmit = (e) => {
        e.preventDefault()

        if (data.categories.length <= 0) {
            SweetAlert.fire({ title: 'Form is Not Complete!', text: 'Categories is must more than 1!', icon: 'error' })
            return
        }

        setIsSubmitting(true)

        let otherImageSessions = []

        otherImage.map((v, i) => {
            otherImageSessions = [...otherImageSessions, v.serverId]
        })

        if (searchParams.get('edit') === null) {
            axios.post(API_STORE_PRODUCT + '/', {
                ...data,
                images: JSON.stringify({
                    main_photo_session: mainImage[0].serverId,
                    other_photo_session: [...otherImageSessions]
                }),
                description: editorDataDescription,
                how_to_apply: editorDataHTA,
                ingredients: editorDataIngredients
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/store/product/list');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {

                    })
                }
            }).finally(() => {
                setIsSubmitting(false)
            })
        } else {
            axios.put(API_STORE_PRODUCT + '/', {
                ...data,
                id: parseInt(searchParams.get('edit')),
                images: JSON.stringify({
                    main_photo_session: mainImage[0].serverId ? mainImage[0].serverId : data.images.main_photo_session,
                    other_photo_session: otherImageSessions.length > 0 ? [...otherImageSessions] : [...data.images.other_photo_session]
                }),
                description: editorDataDescription,
                how_to_apply: editorDataHTA,
                ingredients: editorDataIngredients
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/store/product/list');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {

                    })
                }
            }).finally(() => {
                setIsSubmitting(false)
            })
        }
    }


    // Image File
    const [mainImage, setMainImage] = useState([]);
    const [otherImage, setOtherImage] = useState([]);

    // Category List
    const [categoriesData, setCategoriesData] = useState([])

    useEffect(() => {
        axios.get(API_STORE_PRODUCT_CATEGORY + '/?pagination=' + CreatePaginationEncoding(10000, 0)).then(r => {
            if (r.status === 200) {
                return r.data['data'];
            }
        }).then(json => {
            setCategoriesData(json['data'])
        }).catch(() => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
            })
        })
    }, [])


    return (
        <Fragment>
            <Card>
                <HeaderCard title={'Tambah Produk Baru'}/>
                <Form className="form theme-form" onSubmit={(e) => handlerSubmit(e)}>

                    <CardBody>
                        <Row>
                            <Col sm="6">
                                <Card>
                                    <CardHeader>
                                        <h5>Main Image (400x400)</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <MainImageUploader mainImage={mainImage} setMainImage={setMainImage}/>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card>
                                    <CardHeader>
                                        <h5>Others Image (400x400)</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <OthersImageUploader otherImage={otherImage} setOtherImage={setOtherImage}/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4} sm={12}>
                                <FormGroup>
                                    <Media>
                                        <Label className="col-form-label m-r-10">Active ?</Label>
                                        <Media body className="text-end icon-state switch-lg">
                                            <Label className="switch">
                                                <Input type="checkbox" checked={data.active} onChange={() => {
                                                    setData({ ...data, active: !data.active })
                                                }}/>
                                                <span className="switch-state bg-primary"/>
                                            </Label>
                                        </Media>
                                    </Media>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Product Name</Label>
                                    <Input required name="name" value={data.name}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="text"
                                           placeholder="Nama Produk A"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Product Categories</Label>
                                    <Typeahead
                                        // inputProps={{
                                        //     required: true
                                        // }}
                                        // disabled={searchParams.get('edit') !== null}
                                        id="pc-tah"
                                        labelKey="name"
                                        multiple={true}
                                        options={categoriesData}
                                        placeholder="Choose Categories ..."
                                        allowNew={false}
                                        selected={data.categories}
                                        onChange={(option) => {
                                            setData({ ...data, categories: option })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Product SKU</Label>
                                    <Input required name="sku" value={data.sku}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Product BPOM Number</Label>
                                    <Input required name="bpom_number" value={data.bpom_number}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Product Halal Number</Label>
                                    <Input required name="halal_number" value={data.halal_number}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Price</Label>
                                    <Input required name="price" value={data.price}
                                           onChange={(e) => {
                                               // handlerSetFormNumeric(e)
                                               setData({
                                                   ...data,
                                                   price: parseInt(e.target.value),
                                                   selling_price: parseInt(e.target.value)
                                               })
                                           }}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Selling Price</Label>
                                    <Input required name="selling_price" value={data.selling_price}
                                           onChange={(e) => handlerSetFormNumeric(e)}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className="">
                            <Label>Whatsapp Number</Label>
                            <InputGroup>
                                <InputGroupText>{'+62'}</InputGroupText>
                                <Input required name="wa_number" value={data.wa_number}
                                       onChange={(e) => handlerSetFormNumeric(e)}
                                       className="form-control"
                                       type="text"
                                       aria-label="Whatsapp Number"/>
                            </InputGroup>
                        </FormGroup>
                        <Row>
                            <Col>
                                <FormGroup className="mb-0">
                                    <Label>Whatsapp Text</Label>
                                    <Input required name="wa_text" value={data.wa_text}
                                           onChange={(e) => handlerSetFormString(e)}
                                           type="textarea"
                                           className="form-control btn-square" rows="3"/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Product Description</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorDataDescription}
                                        config={{
                                            extraPlugins: [MyCustomUploadAdapterPlugin],
                                        }}
                                        onChange={(event, editor) => {
                                            setEditorDataDescription(editor.getData())
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>How To Apply</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorDataHTA}
                                        config={{
                                            extraPlugins: [MyCustomUploadAdapterPlugin],
                                        }}
                                        onChange={(event, editor) => {
                                            setEditorDataHTA(editor.getData())
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Ingredients</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorDataIngredients}
                                        config={{
                                            extraPlugins: [MyCustomUploadAdapterPlugin],
                                        }}
                                        onChange={(event, editor) => {
                                            setEditorDataIngredients(editor.getData())
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>


                    <CardFooter className="text-end">
                        <LoaderButton activeLoading={isSubmitting}/>
                        <Link to={'/store/product/list'}>
                            <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                        </Link>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default CreateProductForms;