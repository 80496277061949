import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Media, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';

const ServiceManagement = () => {
    const initialCustomerServiceData = {
        active: false,
        name: '',
        wa_number: '',
        default_text: ''
    }

    const [data, setData] = useState({
        reseller: {
            name: '',
            wa_number: '',
            default_text: ''
        },
        customer_service: [
            {
                active: false,
                name: '',
                wa_number: '',
                default_text: ''
            }
        ]
    })


    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_SERVICE').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])

    const handlerSubmit = (e) => {
        e.preventDefault();

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_SERVICE',
            value: JSON.stringify({ ...data })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }


    return (
        <Fragment>
            <Card>
                <HeaderCard title="Service Management"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <h6>Reseller Join WA Config</h6>
                        <hr/>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Name</Label>
                            <Input className="form-control"
                                   value={data.reseller.name}
                                   onChange={e => setData({
                                       ...data, reseller: {
                                           ...data.reseller,
                                           name: e.target.value
                                       }
                                   })}
                                   type="text" placeholder="Name"/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">WA Number (Start with 62)</Label>
                            <Input className="form-control"
                                   value={data.reseller.wa_number}
                                   onChange={e => setData({
                                       ...data, reseller: {
                                           ...data.reseller,
                                           wa_number: parseInt(e.target.value)
                                       }
                                   })}
                                   type="text" placeholder="Wa Number"/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Default Text</Label>
                            <Input className="form-control"
                                   value={data.reseller.default_text}
                                   onChange={e => setData({
                                       ...data, reseller: {
                                           ...data.reseller,
                                           default_text: e.target.value
                                       }
                                   })}
                                   type="textarea" placeholder="Default Text"/>
                        </FormGroup>
                        <h6>Customer Service WA Config</h6>
                        <hr/>

                        {
                            data.customer_service.map((v, i) => {
                                return <Row>
                                    <Col md={1}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">.</Label>
                                            <Media body className="text-end icon-state switch-lg" style={{
                                                width: '100%'
                                            }}>
                                                <Label className="switch">
                                                    <Input type="checkbox" checked={data.customer_service[i].active}
                                                           onChange={() => {
                                                               let old = data.customer_service
                                                               old[i].active = !old[i].active

                                                               setData({
                                                                   ...data,
                                                                   customer_service: old
                                                               })
                                                           }}/>
                                                    <span className="switch-state bg-primary"/>
                                                </Label>
                                            </Media>
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">Name</Label>
                                            <Input className="form-control"
                                                   value={data.customer_service[i].name}
                                                   onChange={e => {
                                                       let old = data.customer_service
                                                       old[i].name = e.target.value

                                                       setData({
                                                           ...data,
                                                           customer_service: old
                                                       })
                                                   }}
                                                   type="text" placeholder="Name"/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">WA Number (Start with 62)</Label>
                                            <Input className="form-control"
                                                   value={data.customer_service[i].wa_number}
                                                   onChange={e => {
                                                       let old = data.customer_service
                                                       old[i].wa_number = parseInt(e.target.value)

                                                       setData({
                                                           ...data,
                                                           customer_service: old
                                                       })
                                                   }}
                                                   type="text" placeholder="Wa Number"/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label htmlFor="exampleFormControlInput1">Default Text</Label>
                                            <Input className="form-control"
                                                   value={data.customer_service[i].default_text}
                                                   onChange={e => {
                                                       let old = data.customer_service
                                                       old[i].default_text = e.target.value

                                                       setData({
                                                           ...data,
                                                           customer_service: old
                                                       })
                                                   }}
                                                   type="textarea" placeholder="Default Text"/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={1}>
                                        <Label htmlFor="exampleFormControlInput1">.</Label>
                                        <Btn attrBtn={{
                                            color: 'danger',
                                            className: 'm-r-15',
                                            type: 'button',
                                            onClick: () => {
                                                let newArr = data.customer_service
                                                newArr.splice(i, 1)

                                                // console.log(newArr)


                                                setData({
                                                    ...data,
                                                    customer_service: [...newArr]
                                                })
                                            }
                                        }}>Delete</Btn>
                                    </Col>
                                    <Col md={12}>
                                        <hr/>
                                    </Col>
                                </Row>
                            })
                        }
                        <Col md={12}>
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <Btn attrBtn={{
                                        color: 'primary', type: 'button', style: { width: '100%' }, onClick: () => {
                                            setData({
                                                ...data,
                                                customer_service: [...data.customer_service, initialCustomerServiceData]
                                            })
                                        }
                                    }}>Add
                                        Customer Service</Btn>
                                </Col>
                            </Row>
                        </Col>


                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default ServiceManagement;