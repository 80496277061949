import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { API_CDN_CNF, API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import { FilePond } from 'react-filepond';

const NewsletterThemeCard = () => {
    const [data, setData] = useState({
        background_image: '',
        title: '',
        subtitle: '',
    })


    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_THEME_NEWSLETTER').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })
            if (json.background_image && json.background_image !== '') {
                setMainImage([{
                    source: json.background_image,
                    options: { type: 'local' }
                }])
            }
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])


    const handlerSubmit = (e) => {
        e.preventDefault();

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_THEME_NEWSLETTER',
            value: JSON.stringify({
                ...data,
                background_image: mainImage[0] ? mainImage[0].serverId : '',
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }


    const [mainImage, setMainImage] = useState([])
    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage Newsletter Theme"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Background Photo (1900 X 600)</Label>
                                    <FilePond
                                        // required
                                        allowMultiple={false}
                                        allowImageCrop={true}
                                        allowImageTransform={true}
                                        allowFileSizeValidation={true}
                                        imageResizeTargetHeight={600}
                                        files={mainImage}
                                        onupdatefiles={setMainImage}
                                        maxFiles={1}
                                        imageCropAspectRatio={'19:6'}
                                        acceptedFileTypes={['image/*']}
                                        maxFileSize={'1MB'}
                                        server={API_CDN_CNF}
                                        name="files"
                                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Title</Label>
                                    <Input className="form-control"
                                           value={data.title}
                                           onChange={e => setData({ ...data, title: e.target.value })}
                                           type="text" placeholder="Enter some text"/>
                                </FormGroup>

                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Subtitle</Label>
                                    <Input className="form-control"
                                           value={data.subtitle}
                                           onChange={e => setData({ ...data, subtitle: e.target.value })}
                                           type="text" placeholder="Enter some text"/>
                                </FormGroup>

                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        {/*<Btn attrBtn={{ color: 'light' }}>Cancel</Btn>*/}
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default NewsletterThemeCard;