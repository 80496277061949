import React, { Fragment, useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Category, Content, Post, Title } from '../../../Constant';
import BgImageUploader from './BgImageUploader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { API_BLOG, API_BLOG_CATEGORY } from '../../../api/server';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import SweetAlert from 'sweetalert2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '../../../Components/CK5/build-classic'
// import ClassicEditor from './ClassicEditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';
import { Btn } from '../../../AbstractElements';

const FormPost = () => {
    // ClassicEditor.builtinPlugins = [...ClassicEditor.builtinPlugins, Alignment]

    const [data, setData] = useState({
        id: 0,
        title: '',
        content: '',
        created_at: '',
        bg_image_id: '',
        categories: []
    })
    const [searchParams, setSearchParams] = useSearchParams();
    const [categoriesData, setCategoriesData] = useState([])


    useEffect(() => {
        if (searchParams.get('edit') !== null) {
            axios.get(API_BLOG + '/?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({ id: parseInt(searchParams.get('edit')) })).then(r => {
                if (r.status === 200) {
                    return r.data['data'];
                }
            }).then(json => {
                setData(json['data'][0])
                setMainImage([{
                    source: json['data'][0]['bg_image_id'],
                    options: { type: 'local' }
                }])
                setEditorData(json['data'][0]['content'])
            }).catch(() => {
                SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                })
            })
        }
    }, [])

    const [mainImage, setMainImage] = useState([]);


    useEffect(() => {
        axios.get(API_BLOG_CATEGORY + '/?pagination=' + CreatePaginationEncoding(10000, 0)).then(r => {
            if (r.status === 200) {
                return r.data['data'];
            }
        }).then(json => {
            setCategoriesData(json['data'])
        }).catch(() => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
            })
        })
    }, [])

    const [editorData, setEditorData] = useState('')

    const nav = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()

        if (searchParams.get('edit') === null) {
            axios.post(API_BLOG + '/', {
                ...data,
                content: editorData,
                bg_image_id: mainImage[0].serverId
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/content/blog/post');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        } else {
            axios.put(API_BLOG + '/', {
                ...data,
                content: editorData,
                bg_image_id: mainImage[0].serverId
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/content/blog/post');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        }
    }

    return (
        <Fragment>
            <Form className="row needs-validation" onSubmit={handleSubmit}>
                <Col sm="12">
                    <FormGroup>
                        <Label for="validationCustom01">{Title}:</Label>
                        <Input className="form-control" id="validationCustom01" type="text" placeholder="Post Title"
                               value={data.title}
                               onChange={(e) => setData({ ...data, title: e.target.value })}
                               required=""/>
                        <div className="valid-feedback">{'Looks good!'}</div>
                    </FormGroup>
                    <FormGroup>
                        <Label>Background Image (825 x 385) :</Label>
                        <BgImageUploader mainImage={mainImage} setMainImage={setMainImage}/>
                    </FormGroup>
                    <FormGroup>
                        <div className="col-form-Label">{Category}:
                            <Typeahead
                                disabled={searchParams.get('edit') !== null}
                                id="pc-tah"
                                labelKey="name"
                                multiple={true}
                                options={categoriesData}
                                placeholder="Choose Categories ..."
                                allowNew={false}
                                selected={data.categories}
                                onChange={(option) => {
                                    setData({ ...data, categories: option })
                                }}
                            />
                        </div>
                    </FormGroup>
                    <div className="email-wrapper">
                        <div className="theme-form">
                            <FormGroup>
                                <Label>{Content}:</Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={editorData}
                                    config={{
                                        extraPlugins: [MyCustomUploadAdapterPlugin],
                                        toolbar: {
                                            items: [
                                                'heading',
                                                '|',
                                                'bold',
                                                'italic',
                                                'link',
                                                'bulletedList',
                                                'numberedList',
                                                '|',
                                                'alignment',
                                                'outdent',
                                                'indent',
                                                'uploadImage',
                                                'blockQuote',
                                                'mediaEmbed',
                                                'undo',
                                                'redo'
                                            ]
                                        },
                                        mediaEmbed: {
                                            previewsInData: true
                                        }
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setEditorData(data)
                                    }}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </Col>
                <div className="btn-showcase">
                    <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{Post}</Btn>
                    <Btn attrBtn={{
                        color: 'light', onClick: () => {
                            nav('/content/blog/post')
                        }
                    }}>Cancel</Btn>
                </div>
            </Form>
        </Fragment>
    );
};

export default FormPost;