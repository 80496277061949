import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Edit, Trash2 } from 'react-feather';
import TodoContext from '../../../../_helper/Todo';
import { Btn, H4, LI, UL } from '../../../../AbstractElements';
import CarouselForm from './carousel_form';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../../api/server';
import SweetAlert from 'sweetalert2';

const MobileCarouselList = () => {
    const { allTodos, removeItems } = useContext(TodoContext);

    useEffect(() => {
    }, [allTodos]);


    const handleRemove = (ts) => {
        const allDataProcessed = data.filter((v, i, a) => {
            return v.timestamp !== ts
        })

        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.post(API_CONFIGURATOR + '/', {
                    key: 'HP_CAROUSEL_MOBILE',
                    value: JSON.stringify({
                        data: [
                            ...allDataProcessed,
                        ]
                    })
                }).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire('Good Job!', 'Data Delete Success', 'success')
                        setData([
                                ...allDataProcessed,
                            ]
                        )
                    }
                }).catch(() => {
                    SweetAlert.fire('Failed!', 'Data Delete Failed', 'error')
                })
            }
        });
    };

    const [selectedData, setSelectedData] = useState({})
    const [data, setData] = useState([])

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/HP_CAROUSEL_MOBILE').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData(json['data'])
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        }).finally(() => {
            setAddTask('');
            document.getElementById('addTaskBtn').classList.remove('hide');
        })
    }, [])

    useEffect(() => {
        openTaskWrapper()
    }, [selectedData])

    const [addTask, setAddTask] = useState('');

    const openTaskWrapper = () => {
        setAddTask(' visible');
        document.getElementById('addTaskBtn').classList.add('hide');
    };

    return (<Fragment>
        <div className="todo-list-body">
            <UL attrUL={{ className: 'simple-list', id: 'todo-list' }}>
                {data && data.length > 0 ? data.map((v, index) => <LI
                    attrLI={{ className: 'task ' }} key={index}>
                    <div className="task-container">
                        <H4 attrH4={{ className: 'task-label' }}>{v.main_title}</H4>
                        <span className="task-action-btn">
                            <span className="action-box large delete-btn"
                                  title="Delete Task"
                                  onClick={() => handleRemove(v.timestamp)}>
                                <Trash2/>
                            </span>
                             <span className="action-box large complete-btn" title="Mark Complete"
                                   onClick={(e) => {
                                       setSelectedData({ ...v })
                                   }}>
                                <Edit/>
                            </span>
                        </span>
                    </div>
                </LI>) : ''}
            </UL>
        </div>


        <div className="todo-list-footer">
            <div className="add-task-btn-wrapper">
                    <span className="add-task-btn" id="addTaskBtn">
                        <Btn attrBtn={{
                            color: 'primary', onClick: () => {
                                setSelectedData(null);
                                openTaskWrapper()
                            }
                        }}>Add New</Btn>
                    </span>
            </div>
            <CarouselForm editData={selectedData} data={data} setData={setData} setAddTask={setAddTask}
                          addTask={addTask}/>
        </div>
    </Fragment>);
};
export default MobileCarouselList;