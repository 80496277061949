import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';

import 'react-modern-drawer/dist/index.css'

import App from './App';
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
    // <React.StrictMode>
    <App/>,
    // </React.StrictMode>,
    // eslint-disable-next-line no-undef
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
