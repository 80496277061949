import React, { Fragment } from 'react';
import OnSaleConfig from './OnSaleConfig';
import BestSellerConfig from './BestSellerConfig';
import DealOfTheWeekConfig from './DealOfTheWeekConfig';

const PromotionManagement = () => {

    return (
        <Fragment>
            <OnSaleConfig/>
            <BestSellerConfig/>
            <DealOfTheWeekConfig/>
        </Fragment>
    );
};

export default PromotionManagement;