import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { API_CDN_CNF } from '../../../api/server';
import { FilePond } from 'react-filepond';

export const PopupWithoutFormAdjustment = ({ popupData, setPopupData, imageRef }) => {
    const popupSizeOption = [
        {
            value: 'px',
            label: 'PX'
        },
        {
            value: 'vh',
            label: 'Viewer Height'
        },
        {
            value: 'vw',
            label: 'Viewer Width'
        }
    ]

    const popupWithButtonOption = [
        {
            value: true,
            label: 'Yes'
        },
        {
            value: false,
            label: 'No'
        },
    ]

    const popupBackgroundImageSizeOption = [
        {
            value: 'contain',
            label: 'Contain'
        },
        {
            value: 'cover',
            label: 'Cover'
        },
    ]

    const [bgImage, setBgImage] = useState('')
    const [bgImageFiles, setBgImageFiles] = useState([])

    useEffect(() => {
        console.log(bgImage)
        setPopupData({
            ...popupData,
            background: {
                ...popupData.background,
                local_url: bgImage
            }
        })

    }, [bgImage])

    useEffect(() => {
        if (popupData.background.server_id !== '') {
            setBgImageFiles([{
                source: popupData.background.server_id,
                options: { type: 'local' }
            }])
        }
    }, [popupData.background.server_id])

    return (
        <>
            {/*Size Management*/}
            <Row>
                <Col md={12} style={{
                    marginTop: '45px'
                }}>
                    <h6>Size Adjustment</h6>
                    <hr/>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>Width</Label>
                        <Input className="form-control" type="text"
                               value={popupData.size.width}
                               onChange={e => setPopupData({
                                   ...popupData,
                                   size: { ...popupData.size, width: parseInt(e.target.value) }
                               })}
                               placeholder="ABCDEF"/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>of</Label>
                        <Input type="select" name="popup-type" className="form-control digits"
                               defaultValue={popupData.size.width_suffix}
                               onChange={(e) => {
                                   setPopupData({
                                       ...popupData,
                                       size: {
                                           ...popupData.size,
                                           width_suffix: e.target.value
                                       }
                                   })
                               }}>
                            {
                                popupSizeOption.map((v) => {
                                    return <option key={v.label} value={v.value}>{v.label}</option>
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>Height</Label>
                        <Input className="form-control" type="text"
                               value={popupData.size.height}
                               onChange={e => setPopupData({
                                   ...popupData,
                                   size: { ...popupData.size, height: parseInt(e.target.value) }
                               })}
                               placeholder="ABCDEF"/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>of</Label>
                        <Input type="select" name="popup-type" className="form-control digits"
                               defaultValue={popupData.size.height_suffix}
                               onChange={(e) => {
                                   setPopupData({
                                       ...popupData,
                                       size: {
                                           ...popupData.size,
                                           height_suffix: e.target.value
                                       }
                                   })
                               }}>
                            {
                                popupSizeOption.map((v) => {
                                    return <option key={v.label} value={v.value}>{v.label}</option>
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            {/*Button Management*/}
            <Row>
                <Col md={12} style={{
                    marginTop: '45px'
                }}>
                    <h6>Button Adjustment</h6>
                    <hr/>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label>With Button</Label>
                        <Input type="select" name="popup-type" className="form-control digits"
                               defaultValue={popupData.button.enabled}
                               onChange={(e) => {
                                   setPopupData({
                                       ...popupData,
                                       button: {
                                           ...popupData.button,
                                           enabled: e.target.value === 'true'
                                       }
                                   })
                               }}>
                            {
                                popupWithButtonOption.map((v) => {
                                    return <option key={v.label} value={v.value}>{v.label}</option>
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={9}/>
                <Col md={1}>
                    <FormGroup>
                        <Label>Bg. Color</Label>
                        <Input
                            name="bg-color"
                            type="color"
                            onChange={(e) => {
                                setPopupData({
                                    ...popupData,
                                    button: {
                                        ...popupData.button,
                                        background_color: e.target.value
                                    }
                                })
                            }}
                            // disabled={true}
                            style={{
                                height: '35px'
                            }}/>
                    </FormGroup>
                </Col>

                <Col md={1}>
                    <FormGroup>
                        <Label>Text Color</Label>
                        <Input type="color"
                               name="txt-color"
                               onChange={(e) => {
                                   setPopupData({
                                       ...popupData,
                                       button: {
                                           ...popupData.button,
                                           text_color: e.target.value
                                       }
                                   })
                               }}
                            // disabled={true}
                               style={{
                                   height: '35px'
                               }}/>
                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <Label>Button Text</Label>
                        <Input className="form-control" type="text"
                            // disabled={true}
                               value={popupData.button.label}
                               onChange={e => {
                                   setPopupData({
                                       ...popupData,
                                       button: {
                                           ...popupData.button,
                                           label: e.target.value
                                       }
                                   })
                               }}
                               placeholder="ABCDEF"/>
                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <Label>Button Link</Label>
                        <Input className="form-control" type="text"
                               value={popupData.button.link}
                               onChange={e => {
                                   setPopupData({
                                       ...popupData,
                                       button: {
                                           ...popupData.button,
                                           link: e.target.value
                                       }
                                   })
                               }}
                               placeholder="ABCDEF"/>
                    </FormGroup>
                </Col>

                <Col md={8}>
                    <FormGroup>
                        <Label>Button Width</Label>
                        <Input className="form-control" type="text"
                               value={popupData.button.width}
                               onChange={e => setPopupData({
                                   ...popupData,
                                   button: { ...popupData.button, width: parseInt(e.target.value) }
                               })}
                               placeholder="ABCDEF"/>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label>of</Label>
                        <Input type="select" name="popup-type" className="form-control digits"
                               defaultValue={popupData.button.width_suffix}
                               onChange={(e) => {
                                   setPopupData({
                                       ...popupData,
                                       button: {
                                           ...popupData.button,
                                           button: e.target.value
                                       }
                                   })
                               }}>
                            {
                                popupSizeOption.map((v) => {
                                    return <option key={v.label} value={v.value}>{v.label}</option>
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </Row>

            {/*Background Management */}
            <Row>

                <Col md={12} style={{
                    marginTop: '45px'
                }}>
                    <h6>Image Adjustment</h6>
                    <hr/>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Image Size</Label>
                        <Input type="select" name="popup-type" className="form-control digits"
                               defaultValue={popupData.background.size}
                               onChange={(e) => {
                                   setPopupData({
                                       ...popupData,
                                       background: {
                                           ...popupData.background,
                                           size: e.target.value
                                       }
                                   })
                               }}>
                            {
                                popupBackgroundImageSizeOption.map((v) => {
                                    return <option key={v.label} value={v.value}>{v.label}</option>
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label>Background Image</Label>
                        <FilePond
                            ref={imageRef}
                            files={bgImageFiles}
                            required
                            allowMultiple={false}
                            instantUpload={false}
                            onupdatefiles={(f) => {
                                setBgImageFiles(f)
                                const t = URL.createObjectURL(f[0].file)
                                setBgImage(t)
                            }}
                            maxFiles={1}
                            acceptedFileTypes={['image/*']}
                            allowFileSizeValidation={true}
                            maxFileSize={'2MB'}
                            server={API_CDN_CNF}
                            name="files"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    )
}