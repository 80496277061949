import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import CarouselImageUploader from './carousel_image_uploader';
import { Btn } from '../../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../../api/server';
import SweetAlert from 'sweetalert2';
import { defaultData } from './const';

const CarouselForm = ({ editData, data, setData, setAddTask, addTask }) => {
    const closeTaskWrapper = () => {
        setAddTask('');
        document.getElementById('addTaskBtn').classList.remove('hide');
    };

    const [currentData, setCurrentData] = useState(defaultData)
    const [imageData, setImageData] = useState([])

    useEffect(() => {
        if (editData !== null && editData !== {}) {
            setCurrentData(editData)
        } else {
            setCurrentData(defaultData)
        }
    }, [editData])

    useEffect(() => {
        if (currentData.image_id !== '') {
            setImageData([{
                source: currentData.image_id,
                options: { type: 'local' }
            }])
        } else {
            setImageData([])
        }
    }, [currentData.image_id])


    const handlerSubmit = (e) => {
        e.preventDefault();

        let img_id = ''

        if (imageData.length > 0) {
            img_id = imageData[0].serverId
        } else {
            img_id = currentData.image_id
        }

        const allDataProcessed = editData !== null ? data.filter((v, i, a) => {
            return v.timestamp !== editData.timestamp
        }) : data

        axios.post(API_CONFIGURATOR + '/', {
            key: 'HP_CAROUSEL',
            value: JSON.stringify({
                data: [
                    ...allDataProcessed,
                    {
                        ...currentData,
                        image_id: img_id,
                        timestamp: window.performance.now()
                    }
                ]
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
                setData([
                        ...allDataProcessed,
                        {
                            ...currentData,
                            image_id: img_id,
                            timestamp: window.performance.now()
                        }
                    ]
                )
                setCurrentData(defaultData)
                setImageData([])
                closeTaskWrapper()

            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }

    return (
        <Fragment>
            <div className={'new-task-wrapper' + addTask}>
                <Form onSubmit={handlerSubmit}>
                    <Row>
                        <Col md={6} sm={12} lg={6}>
                            <div className="dz-message needsclick">
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Carousel Image (1900 x 700)</Label>
                                    <CarouselImageUploader image={imageData} setImage={setImageData}/>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col md={6} sm={12} lg={6}>
                            <FormGroup>
                                <Label htmlFor="exampleFormControlInput1">Order</Label>
                                <Input
                                    value={currentData.order}
                                    onChange={e => setCurrentData({ ...currentData, order: parseInt(e.target.value) })}
                                    className="form-control" type="number" placeholder="0"/>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="exampleFormControlInput1">Subtitle</Label>
                                <Input
                                    value={currentData.subtitle}
                                    onChange={e => setCurrentData({ ...currentData, subtitle: e.target.value })}
                                    className="form-control" type="text" placeholder="This is my subtitle"/>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="exampleFormControlInput1">Main Title</Label>
                                <Input
                                    value={currentData.main_title}
                                    onChange={e => setCurrentData({ ...currentData, main_title: e.target.value })}
                                    className="form-control" type="text" placeholder="This is my title"/>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="exampleFormControlInput1">Button Redirect URL</Label>
                                <Input
                                    value={currentData.url}
                                    onChange={e => setCurrentData({ ...currentData, url: e.target.value })}
                                    className="form-control" type="text" placeholder="http://www.ebright.com"/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Btn attrBtn={{
                        color: 'danger', className: 'cancel-btn', id: 'close-task-panel', onClick: closeTaskWrapper
                    }}>Cancel</Btn>
                    <Btn attrBtn={{
                        color: 'success', className: 'ms-3 add-new-task-btn', id: 'add-task', type: 'submit'
                    }}>Save</Btn>
                </Form>

            </div>
        </Fragment>
    )
}

export default CarouselForm