import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H5 } from '../../../AbstractElements';
import React, { useState } from 'react';
import Modal from 'react-modal';

export const PopupDisplay = ({ popupData, setPopupData }) => {

    const [displayState, setDisplayState] = useState(false)

    const renderWithoutForm = () => {
        return <Row className="justify-content-center" style={{
            width: `${popupData.size.width}${popupData.size.width_suffix}`,
            border: '1px solid #000000'
        }}>
            <Col md={12} className="p-0">
                <div style={{

                    width: `${popupData.size.width}${popupData.size.width_suffix}`,
                    height: `${popupData.size.height}${popupData.size.height_suffix}`,
                    backgroundColor: '#CCCCCC',
                    background: `url(${popupData.background.local_url}) no-repeat center`,
                    backgroundSize: `${popupData.background.size}`
                }}/>
            </Col>


            {popupData.button.enabled === true && <Col md="auto" className="p-0">
                <button className="btn" style={{
                    marginTop: '15px',
                    width: `${popupData.button.width}${popupData.button.width_suffix}`,
                    color: popupData.button.text_color,
                    backgroundColor: `${popupData.button.background_color}`,
                    borderRadius: '9999999px'
                }}>
                    {popupData.button.label}
                </button>
            </Col>}
        </Row>
    }
    const renderWithForm = () => {
        return (
            <Row
                className={`justify-content-center ${popupData.background.form.image_position !== 'left' && 'flex-row-reverse'}`}
                style={{
                    width: `${popupData.size.width}${popupData.size.width_suffix}`,
                    height: `${popupData.size.height}${popupData.size.height_suffix}`,
                    border: '1px solid #000000'
                }}>
                <Col md={popupData.background.form.background_grid_size} className="p-0">
                    <div style={{
                        width: `100%`,
                        height: `100%`,
                        backgroundColor: '#CCCCCC',
                        background: `url(${popupData.background.local_url}) no-repeat center`,
                        backgroundSize: `${popupData.background.size}`
                    }}/>
                </Col>
                <Col md={12 - popupData.background.form.background_grid_size}
                     style={{
                         padding: '16px 16px',
                         overflowY: 'auto',
                         height: '100%',
                         backgroundColor: `${popupData.background.form.background_color}`,
                     }}
                >
                    <h3 style={{ width: '100%' }}>{popupData.form_attribute.headline}</h3>
                    <p style={{ width: '100%', marginBottom: '16px' }}>{popupData.form_attribute.tagline}</p>
                    {popupData.form_attribute.email === true && <FormGroup>
                        <Label>Email</Label>
                        <Input className="form-control" type="text"
                               onChange={e => {

                               }}
                               placeholder="Enter Text Here"/>
                    </FormGroup>}
                    {popupData.form_attribute.name === true && <FormGroup>
                        <Label>Name</Label>
                        <Input className="form-control" type="text"
                               onChange={e => {

                               }}
                               placeholder="Enter Text Here"/>
                    </FormGroup>}
                    {popupData.form_attribute.wa === true && <FormGroup>
                        <Label>Whatsapp Number</Label>
                        <Input className="form-control" type="text"
                               onChange={e => {

                               }}
                               placeholder="Enter Text Here"/>
                    </FormGroup>}
                    {
                        popupData.button.enabled === true &&
                        <button className="btn" style={{
                            width: `${popupData.button.width}${popupData.button.width_suffix}`,
                            color: popupData.button.text_color,
                            backgroundColor: `${popupData.button.background_color}`,
                            borderRadius: '9999999px'
                        }}>
                            {popupData.button.label}
                        </button>
                    }
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <Row className="justify-content-between">
                        <Col md="auto">
                            <H5>Display</H5>
                        </Col>
                        <Col md="auto">
                            <Btn attrBtn={{
                                color: 'primary',
                                type: 'button',
                                onClick: () => {
                                    setDisplayState(true)
                                }
                            }}>Preview</Btn>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody style={{
                    overflow: 'auto',
                    maxHeight: '600px',
                }}>
                    {popupData.type === 1 && renderWithoutForm()}
                    {popupData.type === 2 && renderWithForm()}
                </CardBody>
            </Card>
            <Modal
                isOpen={displayState}
                // shouldCloseOnOverlayClick={true}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => {
                    setDisplayState(false)
                }}
                style={
                    {
                        overlay: {
                            zIndex: 999999,
                            backgroundColor: 'rgba(0, 0, 0, .4)',
                        },
                        content: {
                            padding: 0,
                            backgroundColor: 'rgba(255,255,255, 0)',
                            border: 'none',
                            overflow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            width: 'fit-content',
                            height: 'fit-content',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    }
                }
            >
                <Row className="justify-content-center align-items-center align-content-center">
                    <Col md="auto">
                        {popupData.type === 1 && renderWithoutForm()}
                        {popupData.type === 2 && renderWithForm()}
                    </Col>
                </Row>

            </Modal>
        </>

    )
}