import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import SecondaryImageUploader from './CSUploadImageSecondary';
import MainImageUploader from './CSUploadImageMain';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const CompanyStoryManagement = () => {
    const [data, setData] = useState({
        caption: '',
        title: '',
        content: '',
        main_image: '',
        secondary_image: ''
    })

    const [editorData, setEditorData] = useState('')
    const [mainImage, setMainImage] = useState([]);
    const [secondaryImage, setSecondaryImage] = useState([]);

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_ABOUT_US_FH').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            console.log(json)

            setData({ ...json })

            setEditorData(json.content)

            if (json.main_image && json.main_image !== '') {
                setMainImage([{
                    source: json['main_image'],
                    options: { type: 'local' }
                }])
            }

            if (json.secondary_image && json.secondary_image !== '') {
                setSecondaryImage([{
                    source: json['secondary_image'],
                    options: { type: 'local' }
                }])
            }
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])


    const handlerSubmit = (e) => {
        e.preventDefault();


        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_ABOUT_US_FH',
            value: JSON.stringify({
                ...data,
                content: editorData,
                main_image: mainImage.length > 0 ? mainImage[0].serverId : data.main_image,
                secondary_image: secondaryImage.length > 0 ? secondaryImage[0].serverId : data.secondary_image,
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }


    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage About Us - First Section"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col sm="6">
                                <Card>
                                    <CardHeader>
                                        <h5>Main Image (390 x 455) </h5>
                                    </CardHeader>
                                    <CardBody>
                                        <MainImageUploader mainImage={mainImage} setMainImage={setMainImage}/>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card>
                                    <CardHeader>
                                        <h5>Secondary Image (325 x 385)</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <SecondaryImageUploader secondaryImage={secondaryImage}
                                                                setSecondaryImage={setSecondaryImage}/>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Caption</Label>
                                    <Input className="form-control" type="text"
                                           value={data.caption}
                                           onChange={e => setData({ ...data, caption: e.target.value })}
                                           placeholder="ABCDEF"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Title</Label>
                                    <Input className="form-control" type="text"
                                           value={data.title}
                                           onChange={e => setData({ ...data, title: e.target.value })}
                                           placeholder="ABCDEF"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Content</Label>

                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorData}
                                        config={{
                                            extraPlugins: [MyCustomUploadAdapterPlugin],
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data)
                                        }}
                                    />


                                    {/*<Input className="form-control" type="text"*/}
                                    {/*       value={data.content}*/}
                                    {/*       onChange={e => setData({ ...data, content: e.target.value })}*/}
                                    {/*       placeholder="ABCDEF"/>*/}
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default CompanyStoryManagement;