import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs, Btn } from '../../../AbstractElements';
import { Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Content, Discard, Post } from '../../../Constant';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';

const PrivacyPolicyManagement = () => {
    const [editorData, setEditorData] = useState('')

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_PVP').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setEditorData(json.content)
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })

    }, [])


    const handlerOnSubmit = (e) => {
        e.preventDefault()
        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_PVP',
            value: JSON.stringify({
                content: editorData
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Privacy Policy" parent="Company" title="Privacy Policy Management"/>
            <Container fluid={true}>
                <Form onSubmit={handlerOnSubmit}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <HeaderCard title="Privacy Policy"/>
                                <CardBody className="add-post">
                                    <div className="email-wrapper">
                                        <div className="theme-form">
                                            <FormGroup>
                                                <Label>{Content}:</Label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={editorData}
                                                    config={{
                                                        extraPlugins: [MyCustomUploadAdapterPlugin],
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setEditorData(data)
                                                    }}
                                                />
                                                {/*<CKEditor*/}
                                                {/*    editor={ClassicEditor}*/}
                                                {/*    data={editorData}*/}
                                                {/*    onChange={(event, editor) => {*/}
                                                {/*        const data = editor.getData();*/}
                                                {/*        setEditorData(data)*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="btn-showcase">
                                        <Btn attrBtn={{ color: 'primary', type: 'submit' }}>{Post}</Btn>
                                        <Btn attrBtn={{ color: 'light', type: 'reset' }}>{Discard}</Btn>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Fragment>
    )

}

export default PrivacyPolicyManagement