import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';

const SocialMediaManagement = () => {
    const [data, setData] = useState({
        facebook: '',
        instagram: '',
        tiktok: '',
        youtube: '',
        twitter: ''
    })

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_ABOUT_US_SOCIAL_MEDIA').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])


    const handlerSubmit = (e) => {
        e.preventDefault();

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_ABOUT_US_SOCIAL_MEDIA',
            value: JSON.stringify(data)
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }


    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage Social Media URL"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Instagram</Label>
                                    <Input className="form-control" type="text"
                                           value={data.instagram}
                                           onChange={e => setData({ ...data, instagram: e.target.value })}
                                           placeholder="Enter url here"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Twitter</Label>
                                    <Input className="form-control" type="text"
                                           value={data.twitter}
                                           onChange={e => setData({ ...data, twitter: e.target.value })}
                                           placeholder="Enter url here"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Facebook</Label>
                                    <Input className="form-control" type="text"
                                           value={data.facebook}
                                           onChange={e => setData({ ...data, facebook: e.target.value })}
                                           placeholder="Enter url here"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Youtube</Label>
                                    <Input className="form-control" type="text"
                                           value={data.youtube}
                                           onChange={e => setData({ ...data, youtube: e.target.value })}
                                           placeholder="Enter url here"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Tiktok</Label>
                                    <Input className="form-control" type="text"
                                           value={data.tiktok}
                                           onChange={e => setData({ ...data, tiktok: e.target.value })}
                                           placeholder="Enter url here"/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        {/*<Btn attrBtn={{ color: 'light' }}>Cancel</Btn>*/}
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default SocialMediaManagement;