import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Label, Media, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import { Input } from 'react-bootstrap-typeahead';
import DOWImageUploader from './DowImageUpload';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { HexColorPicker } from 'react-colorful';

const DealOfTheWeekConfig = () => {
    // const [selectedData, setSelectedData] = useState([])
    const [image, setImage] = useState([])
    const [data, setData] = useState({
        active: false,
        title: '',
        navigation_title: '',
        button_text: '',
        button_link: '',
        button_background_color: '',
        button_text_color: '',
        image: '',
        date: new Date()
    })

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_DOW').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({
                ...json,
                date: json.date !== null ? new Date(json.date) : new Date()
            })

            if (json.image && json.image !== '') {
                setImage([{
                    source: json.image,
                    options: { type: 'local' }
                }])
            }
        }).catch((err) => {
            SweetAlert.fire('Error!', 'Data Load Failed\n' + err, 'error')
        })
    }, [])

    const handlerSubmit = (e) => {
        e.preventDefault()
        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_DOW',
            value: JSON.stringify({
                ...data,
                image: image.length > 0 ? image[0].serverId : data.image
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }

    return (
        <Fragment>
            <Card>
                <HeaderCard title="Deal of The Week Product"/>
                <Form className="form theme-form" onSubmit={handlerSubmit}>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <Media>
                                    <Label className="col-form-label m-r-10">Active ?</Label>
                                    <Media body className="text-end icon-state switch-lg">
                                        <Label className="switch">
                                            <Input type="checkbox" checked={data.active} onChange={() => {
                                                setData({ ...data, active: !data.active })
                                            }}/>
                                            <span className="switch-state bg-primary"/>
                                        </Label>
                                    </Media>
                                </Media>
                            </Col>
                            <Col md={6}/>
                            <Col md={12}>
                                <FormGroup>
                                    <div className="col-form-Label">Image (1000 x 500):
                                        <DOWImageUploader image={image} setImage={setImage}/>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <Input required name="title" value={data.title}
                                           onChange={(e) => {
                                               // handlerSetFormNumeric(e)
                                               setData({
                                                   ...data,
                                                   title: e.target.value
                                               })
                                           }}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Navigation Title</Label>
                                    <Input required name="navigation_title" value={data.navigation_title}
                                           onChange={(e) => {
                                               // handlerSetFormNumeric(e)
                                               setData({
                                                   ...data,
                                                   navigation_title: e.target.value
                                               })
                                           }}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <div className="col-form-Label">End Date:
                                        <DatePicker className="form-control digits" selected={data.date}
                                                    onChange={(e) => {
                                                        setData({ ...data, date: e })
                                                    }}/>
                                    </div>
                                </FormGroup>
                            </Col>

                            <hr/>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Button Text</Label>
                                    <Input required name="navigation_title" value={data.button_text}
                                           onChange={(e) => {
                                               // handlerSetFormNumeric(e)
                                               setData({
                                                   ...data,
                                                   button_text: e.target.value
                                               })
                                           }}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>

                            <Col md={12}>
                                <FormGroup>
                                    <Label>Button Link</Label>
                                    <Input required name="navigation_title" value={data.button_link}
                                           onChange={(e) => {
                                               // handlerSetFormNumeric(e)
                                               setData({
                                                   ...data,
                                                   button_link: e.target.value
                                               })
                                           }}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABC123456789"/>
                                </FormGroup>
                            </Col>

                            <hr/>

                            <Row>
                                <Col md={6} sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlInput1">Button Background Color</Label>
                                        <Row>
                                            <Col md="auto">
                                                <HexColorPicker color={data.button_background_color} onChange={(v) => {
                                                    setData({
                                                        ...data,
                                                        button_background_color: v
                                                    })
                                                }}/>
                                            </Col>
                                            <Col md="auto">
                                                <Input className="form-control"
                                                       value={data.button_background_color}
                                                       onChange={e => setData({
                                                           ...data,
                                                           button_background_color: e.target.value
                                                       })}
                                                       type="text"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col md={6} sm={12}>
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlInput1">Button Text Color</Label>
                                        <Row>
                                            <Col md="auto">
                                                <HexColorPicker color={data.button_text_color} onChange={(v) => {
                                                    setData({
                                                        ...data,
                                                        button_text_color: v
                                                    })
                                                }}/>
                                            </Col>
                                            <Col md="auto">
                                                <Input className="form-control"
                                                       value={data.button_text_color}
                                                       onChange={e => setData({
                                                           ...data,
                                                           button_text_color: e.target.value
                                                       })}
                                                       type="text"/>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        <Btn attrBtn={{ color: 'light', type: 'submit' }}>Cancel</Btn>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default DealOfTheWeekConfig;