import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { API_STORE_PRODUCT_CATEGORY } from '../../../api/server';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import SweetAlert from 'sweetalert2';
import { Typeahead } from 'react-bootstrap-typeahead';

export const ProductCategoryTypeahead = ({ multiple = false, placeholder = '', onChange, defaultSelectedId = [] }) => {
    const [data, setData] = useState([])
    const [initialData, setInitialData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [wait, setWait] = useState(false)


    useEffect(() => {
        axios.get(API_STORE_PRODUCT_CATEGORY + '/?pagination=' + CreatePaginationEncoding(5, 0)).then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            if (json['data']['data'] !== null) {
                setData(json['data']['data'])
                setInitialData(json['data']['data'])
            }
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])

    useEffect(() => {
        if (searchTerm !== '') {
            axios.get(API_STORE_PRODUCT_CATEGORY + '/?pagination=' + CreatePaginationEncoding(5, 0) + '&filter=' + CreateFilterEncoding({ name: searchTerm })).then(r => {
                if (r.status === 200) {
                    return r.data
                }
            }).then(json => {
                if (json['data']['data'] !== null) {
                    setData(json['data']['data'])
                } else {
                    setData([])
                }
                setWait(false);
            }).catch(() => {
                setData([])
            })
        } else {
            setData(initialData)
        }
    }, [searchTerm])

    return (
        <Fragment>
            <Typeahead
                onInputChange={(t, e) => {
                    setSearchTerm(t)
                }}
                allowNew={false}
                onChange={onChange}
                id="blog-category-typeahead"
                className="mt-2"
                clearButton={false}
                labelKey="name"
                multiple={multiple}
                options={data}
                placeholder={placeholder}
            />
        </Fragment>
    )
}