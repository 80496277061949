import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Card, FormGroup } from 'react-bootstrap';
import { CardBody, CardFooter, Col, Form, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { Btn } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { API_STORE_PARTNERS } from '../../../api/server'
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import { Typeahead } from 'react-bootstrap-typeahead';
import { RegencyTypeahead } from '../../../Components/Common/Component/RegencyTypeahead';

const PartnersForms = ({ id = 0 }) => {
    const options = [
        {
            id: 1,
            name: 'Stockist'
        },
        {
            id: 2,
            name: 'Reseller'
        },
        {
            id: 3,
            name: 'Offline Store'
        },
        {
            id: 4,
            name: 'Agen'
        }
    ]

    const [data, setData] = useState({
        id: 0,
        store_type: [],
        store_name: '',
        store_address: '',
        store_wa: '',
        wa_text: '',
        store_instagram: '',
        store_region_id: 0
    })


    // const [selectedProvince, setSelectedProvince] = useState([])
    const [selectedRegency, setSelectedRegency] = useState([])

    useEffect(() => {
        if (id !== undefined && id !== null && id !== 0) {
            axios.get(API_STORE_PARTNERS + '/?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({ id: id })).then(r => {
                if (r.status === 200) {
                    return r.data['data'];
                }
            }).then(json => {
                if (json['data'].length > 0) {
                    console.log('ABSBDASBD', json['data'])
                    console.log('ABSBDASBDXZXCZXS', json['data'][0]['store_region_id'])

                    setData({
                        ...json['data'][0],
                        store_type: options.filter((e) => e.id === json['data'][0].store_type)
                    })

                    if (json['data'][0]['store_region_id'] !== 0) {
                        setSelectedRegency([json['data'][0]['store_region_id']])
                    }
                }
            }).catch(() => {
                SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                })
            })
        }
    }, [id])


    const handlerSetFormString = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handlerSetFormNumeric = (e) => {
        setData({
            ...data,
            [e.target.name]: parseInt(e.target.value)
        })
    }

    const nav = useNavigate();

    const handlerSubmit = (e) => {
        e.preventDefault()

        if (selectedRegency.length <= 0) {
            SweetAlert.fire({
                title: 'Form is Not Complete!',
                text: 'Kabupaten / Kota is must more than 1!',
                icon: 'error'
            })
            return
        }

        if (id !== 0) {
            axios.put(API_STORE_PARTNERS + '/', {
                ...data,
                store_type: data.store_type[0].id,
                store_region_id: selectedRegency[0]
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(r => {
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        } else {
            axios.post(API_STORE_PARTNERS + '/', {
                ...data,
                store_type: data.store_type[0].id,
                store_region_id: selectedRegency[0]
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/company/store/partner');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        }
    }

    return (
        <Fragment>
            <Card>
                <HeaderCard title={(id === 0 ? 'Tambah' : 'Edit') + ' Store Partner'}/>
                <Form className="form theme-form" onSubmit={(e) => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Type</Label>
                                    <Typeahead
                                        id="pc-tah"
                                        labelKey="name"
                                        multiple={false}
                                        options={options}
                                        placeholder="Choose Store Type ..."
                                        allowNew={false}
                                        onChange={(o) => {
                                            setData({ ...data, store_type: o })
                                        }}
                                        selected={data.store_type}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Store Name</Label>
                                    <Input required name="store_name" value={data.store_name}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABCDE 0123456789"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Store WA</Label>
                                    <InputGroup>
                                        <InputGroupText>{'+62'}</InputGroupText>
                                        <Input required name="store_wa" value={data.store_wa}
                                               onChange={(e) => handlerSetFormString(e)}
                                               className="form-control"
                                               type="text"
                                               aria-label="Whatsapp Number"/>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label>WA Text</Label>
                                    <Input required name="wa_text" value={data.wa_text}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="textarea"
                                           placeholder="ABCDE 0123456789"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Store Instagram</Label>
                                    <Input required name="store_instagram" value={data.store_instagram}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABCDE 0123456789"/>
                                </FormGroup>

                                <hr/>
                                <FormGroup>
                                    <Label>Store Address</Label>
                                    <Input required name="store_address" value={data.store_address}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="textarea"
                                           placeholder="ABCDE 0123456789"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Kota / Kabupaten</Label>
                                    <RegencyTypeahead multiple={false}
                                                      placeholder={'Please Select Province'}
                                                      onChange={(options) => {
                                                          setSelectedRegency(options.map((v, i) => v.id))
                                                      }}
                                                      defaultSelectedId={selectedRegency}/>
                                </FormGroup>

                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{
                            color: 'primary',
                            className: 'm-r-15',
                            type: 'submit',
                        }}
                        >Submit</Btn>
                        <Link to={'/store/product/category-list'}>
                            <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                        </Link>
                    </CardFooter>
                </Form>

            </Card>
        </Fragment>
    );
};

export default PartnersForms;