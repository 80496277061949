import React from 'react'

import { FilePond } from 'react-filepond'
import { API_CDN_TEMP } from '../../../api/server';


function MainImageUploader({ mainImage, setMainImage }) {
    // const [files, setFiles] = useState([])
    return (
        <>
            <FilePond
                required
                allowMultiple={false}
                allowImageCrop={true}
                allowImageTransform={true}
                allowFileSizeValidation={true}
                imageResizeTargetWidth={600}
                files={mainImage}
                onupdatefiles={setMainImage}
                maxFiles={1}
                imageCropAspectRatio={'1:1'}
                acceptedFileTypes={['image/*']}
                maxFileSize={'1MB'}
                server={API_CDN_TEMP}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
            />
        </>

    )
}


export default MainImageUploader