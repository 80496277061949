import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { value } from 'lodash/seq';
import TestimonialImageUploader from './upload_testimonial_image';
import { defaultData } from './data';


const TestimonialForm = ({ editData, allData, setAddTask, setParentData }) => {
    const closeTaskWrapper = () => {
        setAddTask('');
        document.getElementById('addTaskBtn').classList.remove('hide');
    };

    const [data, setData] = useState(defaultData)

    const [image, setImage] = useState([])

    useEffect(() => {
        if (editData !== null && editData !== {}) {
            setData(editData)
        } else {
            setData(defaultData)
        }
    }, [editData])

    useEffect(() => {
        if (data.image_id !== '') {
            setImage([{
                source: data.image_id,
                options: { type: 'local' }
            }])
        } else {
            setImage([])
        }
    }, [data.image_id])

    const handlerOnSubmit = (e) => {
        e.preventDefault()

        let img_id = ''

        if (image.length > 0) {
            img_id = image[0].serverId
        } else {
            img_id = data.image_id
        }

        const allDataProcessed = editData !== null ? allData.filter((v, i, a) => {
            return v.timestamp !== editData.timestamp
        }) : allData

        axios.post(API_CONFIGURATOR + '/', {
            key: 'HP_TESTIMONIAL',
            value: JSON.stringify({
                data: [
                    ...allDataProcessed,
                    {
                        ...data,
                        image_id: img_id,
                        timestamp: window.performance.now()
                    }
                ]
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
                setData(defaultData)
                closeTaskWrapper()
                setParentData([
                    ...allDataProcessed,
                    {
                        ...data,
                        image_id: img_id,
                        timestamp: window.performance.now()
                    }
                ])
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })

    }


    return (
        <Fragment>
            <Form onSubmit={handlerOnSubmit}>
                <Row>
                    <Col md={4} sm={12} lg={4}>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Image (400 x 400)</Label>
                            <TestimonialImageUploader image={image} setImage={setImage}/>
                        </FormGroup>
                    </Col>


                    <Col md={8} sm={12} lg={8}>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Title</Label>
                            <Input
                                value={data.title}
                                onChange={(e) => {
                                    setData({ ...data, title: e.target.value })
                                }}
                                className="form-control" type="text" placeholder="ABCDEFGHIJKLMNOPQRSTUVWXYZ"/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Subtitle</Label>
                            <Input
                                value={data.subtitle}
                                onChange={(e) => {
                                    setData({ ...data, subtitle: e.target.value })
                                }}
                                className="form-control" type="text" placeholder="ABCDEFGHIJKLMNOPQRSTUVWXYZ"/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Stars Count</Label>
                            <Input
                                value={data.starts_count}
                                onChange={(e) => {
                                    if (parseInt(e.target.value) > 5) {
                                        setData({ ...data, starts_count: 5 })
                                    } else {
                                        setData({ ...data, starts_count: parseInt(e.target.value) })
                                    }
                                }}
                                className="form-control" type="number" max={5} placeholder="5"/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Content</Label>
                            <Input
                                value={data.content}
                                onChange={(e) => {
                                    setData({ ...data, content: e.target.value })
                                }}
                                className="form-control" type="textarea" placeholder="ABCDEFGHIJKLMNOPQRSTUVWXYZ"/>
                        </FormGroup>
                    </Col>


                    <Col md={12} lg={12} sm={12} style={{ margin: '16px 0' }}>
                        <Btn attrBtn={{
                            color: 'danger',
                            className: 'cancel-btn',
                            id: 'close-task-panel',
                            onClick: closeTaskWrapper
                        }}>Cancel</Btn>
                        <Btn attrBtn={{
                            color: 'success',
                            className: 'ms-3 add-new-task-btn',
                            id: 'add-task',
                            type: 'submit',
                        }}>
                            Save
                        </Btn>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )

}

export default TestimonialForm