import React from 'react'

import { FilePond } from 'react-filepond'
import { API_CDN_TEMP } from '../../../api/server';


function OthersImageUploader({ otherImage, setOtherImage }) {
    return (
        <>
            <FilePond
                required
                imageResizeTargetWidth={600}
                files={otherImage}
                onupdatefiles={setOtherImage}
                allowMultiple={true}
                imageCropAspectRatio={'1:1'}
                maxFiles={10}
                server={API_CDN_TEMP}
                allowFileSizeValidation={true}
                acceptedFileTypes={['image/*']}
                maxFileSize={'1MB'}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> <br/> Up to 10 Images'
            />
        </>

    )
}


export default OthersImageUploader