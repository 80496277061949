import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { SkinAnalyzerCollectionDataTable } from './SkinAnalyzerCollectionDataTable';


const SkinAnalyzerCollectionManagement = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Table" title="Skin Analyzer Collection" mainTitle="Skin Analyzer Collection"/>
            <SkinAnalyzerCollectionDataTable/>
        </Fragment>
    );

};

export default SkinAnalyzerCollectionManagement;