import React, {Fragment} from 'react';
import {Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import axios from "axios";
import SweetAlert from "sweetalert2";
import {Btn, H3, H4, Image, P} from "../../AbstractElements";
import { API_USER } from '../../api/server';

const MyProfileEdit = ({accountData, setAccountData}) => {
    const handleSubmit = (e) => {
        e.preventDefault()
        axios.put(API_USER + "/", {
            ...accountData
        }, {withCredentials: true}).then(r => {
            if (r.status === 204) {
                SweetAlert
                    .fire({title: 'Good job!', text: 'We\'ve record your data!', icon: 'success'})
                    .then(() => {
                    })
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Hang up, we have some errors.',
                icon: 'error'
            }).then(() => {
            })``
        })
    }


    return (
        <Fragment>
            <Card>
                <CardHeader className="pb-0">
                    <H4 attrH4={{className: 'card-title mb-0'}}>My Profile</H4>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                <CardBody>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-2">
                            <div className="profile-title">
                                <div className="media">
                                    <Image attrImage={{
                                        className: 'img-70 rounded-circle',
                                        alt: '',
                                        src: `${require('../../assets/images/dashboard/1.png')}`
                                    }}/>
                                    <div className="media-body">
                                        <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile`}>
                                            <H3 attrH3={{className: 'mb-1 f-20 txt-primary'}}>{accountData.full_name}</H3>
                                        </Link>
                                        <P>{accountData.role === 1 ? "Owner" : "Admin"}</P>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <FormGroup className="mb-3">
                            <Label className="form-label">Username</Label>
                            <Input className="form-control" placeholder="Username" disabled={true}
                                   value={accountData.username}/>
                        </FormGroup>
                        
                        <FormGroup className="mb-3">
                            <Label className="form-label">New Password</Label>
                            <Input className="form-control" type="password" placeholder="Password"
                                   onChange={(e) => setAccountData({
                                       ...accountData, password: e.target.value
                                   })}/>
                        </FormGroup>
                        <div className="form-footer">
                            <Btn attrBtn={{
                                className: 'btn-block', color: 'primary', onSubmit: handleSubmit
                            }}>Save</Btn>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    );
};
export default MyProfileEdit;