import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Card, FormGroup } from 'react-bootstrap';
import { CardBody, CardFooter, Col, Form, Input, Label, Row } from 'reactstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { Btn, H5 } from '../../../AbstractElements';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { ProductTypeahead } from '../../../Components/Common/Component/ProductTypeahead';
import { Trash2 } from 'react-feather';
import { QuestionTypeahead } from '../../../Components/Common/Component/QuestionTypeahead';
import axios from 'axios';
import { API_SKIN_ANALYZER_QUESTION } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import { PrintCombination } from '../../../Utils/get_possible_combination';

const CreateSkinAnalyzerQuestionForms = () => {
    const blankAnswerData = {
        id: 0,
        timestamp: window.performance.now(),
        answer_code: '',
        answer_text: ''
    }

    const resultSetAction = {
        type: 1,
        value: []
    }

    const [questionData, setQuestionData] = useState({
        question_text: '',
        answer_count: 1,
        result_set: {},
        answer_set: {}
    })

    const [answerData, setAnswerData] = useState([blankAnswerData])

    const [resultSetData, setResultSetData] = useState({})

    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('edit')

    useEffect(() => {
        const res = PrintCombination([...answerData.map((v, i) => i)], answerData.length, questionData.answer_count)

        let map = {}

        res.map((v, i) => {
            if (resultSetData[v.join('')] === undefined) {
                map[v.join('')] = resultSetAction
            } else {
                map[v.join('')] = resultSetData[v.join('')]
            }
        })

        setResultSetData(map)

    }, [answerData, questionData.answer_count])


    useEffect(() => {
        if (id !== undefined && id !== null && id !== 0) {
            axios.get(API_SKIN_ANALYZER_QUESTION + '?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({ id: parseInt(id) })).then(r => {
                if (r.status === 200) {
                    return r.data['data'];
                }
            }).then(json => {
                if (json['data'].length > 0) {
                    const _d = json['data'][0];
                    setQuestionData(_d)
                    setResultSetData(JSON.parse(_d.result_set));
                    setAnswerData(JSON.parse(_d.answer_set));
                }
            }).catch((err) => {
                SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data ' + err, icon: 'error' }).then(() => {
                })
            })
        }
    }, [id])

    useEffect(() => {
        if (id !== undefined && id !== null && id !== 0) {
            axios.get(API_SKIN_ANALYZER_QUESTION + '?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({ id: parseInt(id) })).then(r => {
                if (r.status === 200) {
                    return r.data['data'];
                }
            }).then(json => {
                if (json['data'].length > 0) {
                    const _d = json['data'][0];
                    setQuestionData(_d)
                    setResultSetData(JSON.parse(_d.result_set));
                    setAnswerData(JSON.parse(_d.answer_set));
                }
            }).catch((err) => {
                SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data ' + err, icon: 'error' }).then(() => {
                })
            })
        }
    }, [])

    const nav = useNavigate();

    const handlerSubmit = (e) => {
        e.preventDefault()

        if (id && id !== 0) {
            axios.put(API_SKIN_ANALYZER_QUESTION, {
                ...questionData,
                result_set: JSON.stringify(resultSetData),
                answer_set: JSON.stringify(answerData.map((v, i) => {
                    return {
                        ...v,
                        id: i
                    }
                }))
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        } else {
            axios.post(API_SKIN_ANALYZER_QUESTION, {
                ...questionData,
                result_set: JSON.stringify(resultSetData),
                answer_set: JSON.stringify(answerData.map((v, i) => {
                    return {
                        ...v,
                        id: i
                    }
                }))
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/content/skin-analyzer/question');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        }
    }

    return (
        <Fragment>
            <Card>
                <HeaderCard title={(id === 0 ? 'Tambah' : 'Edit') + ' Skin Analyzer Question'}/>
                <Form className="form theme-form" onSubmit={(e) => handlerSubmit(e)}>
                    <CardBody>
                        <Row style={{ marginBottom: 24 }}>
                            <Col md={9}>
                                <FormGroup>
                                    <Label>Question Text</Label>
                                    <Input required name="question_text" value={questionData.question_text}
                                           onChange={(e) => {
                                               setQuestionData({ ...questionData, question_text: e.target.value })
                                           }}
                                           className="form-control btn-square" type="text"
                                           placeholder="Insert a Question"/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Answer Count</Label>
                                    <Input required name="question_text" value={questionData.answer_count}
                                           onChange={(e) => {
                                               setQuestionData({
                                                   ...questionData,
                                                   answer_count: parseInt(e.target.value)
                                               })
                                           }}
                                           min={1}
                                           max={answerData.length}
                                           className="form-control btn-square" type="number"
                                           placeholder="Insert Choice Count"/>
                                </FormGroup>
                            </Col>
                        </Row>

                        <H5>Answers</H5>
                        <Row style={{ marginBottom: 24 }} className="justify-content-between  align-items-center">
                            {answerData.map((v, i) => {
                                return (
                                    <Col style={{ marginBottom: 16 }} md={12}>
                                        <hr className="solid"/>

                                        <Label>Answer #{i}</Label>

                                        <FormGroup>
                                            <Row className="justify-content-between  align-items-center">
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <Label>Answer Code</Label>
                                                        <Input required name="answer_code"
                                                               value={answerData[i].answer_code}
                                                               onChange={(e) => {
                                                                   let newAnswerData = answerData
                                                                   newAnswerData[i] = {
                                                                       ...newAnswerData[i],
                                                                       answer_code: e.target.value
                                                                   }

                                                                   setAnswerData([...newAnswerData])
                                                               }}
                                                               className="form-control btn-square" type="text"
                                                               placeholder="Ex. A B C"/>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Answer Text</Label>
                                                        <Input required name="question_text"
                                                               value={answerData[i].answer_text}
                                                               onChange={(e) => {
                                                                   let newAnswerData = answerData
                                                                   newAnswerData[i] = {
                                                                       ...newAnswerData[i],
                                                                       answer_text: e.target.value
                                                                   }

                                                                   setAnswerData([...newAnswerData])
                                                               }}
                                                               className="form-control btn-square" type="text"
                                                               placeholder="Insert a Answers Display Text"/>
                                                    </FormGroup>
                                                </Col>
                                                <Col style={{ marginBottom: 16 }} md={'auto'}>
                                                    <FormGroup>
                                                        <Label></Label><br/>
                                                        <Btn attrBtn={{
                                                            color: 'danger',
                                                            className: 'btn btn-xs',
                                                            disabled: answerData.length <= 1,
                                                            onClick: () => {
                                                                let newAnswerData = answerData.filter((e) => e !== v)
                                                                setAnswerData([...newAnswerData])
                                                            }
                                                        }}>
                                                            <Trash2 size={18}/>
                                                        </Btn>
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                )
                            })}
                            <Col style={{ marginBottom: 16 }} md={12}>
                                <Btn attrBtn={{
                                    color: 'primary',
                                    className: 'm-r-15',
                                    onClick: () => {
                                        setAnswerData([...answerData, {
                                            ...blankAnswerData
                                        }])
                                    }
                                }}
                                >Add New Answer</Btn>
                            </Col>
                        </Row>

                        <H5>Result Set</H5>
                        {Array.from(Object.entries(resultSetData)).map((v, i) => {
                            console.log('RSD', resultSetData[v[0]])
                            return (
                                <Row style={{ marginBottom: 24 }}>
                                    <Col style={{ marginBottom: 16 }} md={4}>
                                        <FormGroup>
                                            <Label>Result for Selected #{v[0].split('').join(' #')}</Label>
                                            <Select
                                                value={[
                                                    {
                                                        value: 1,
                                                        label: 'Show Product'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: 'Go to Question'
                                                    },
                                                ].find(e => e.value === resultSetData[v[0]].type) || {
                                                    value: 1,
                                                    label: 'Show Product'
                                                }}
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: 'Show Product'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: 'Go to Question'
                                                    },
                                                ]}
                                                onChange={(value) => {
                                                    let newResultSetData = resultSetData
                                                    newResultSetData[v[0]] = {
                                                        ...newResultSetData[v[0]],
                                                        type: value.value
                                                    }

                                                    setResultSetData({ ...newResultSetData })
                                                }}
                                                className="js-example-basic-single col-sm-12"/>
                                        </FormGroup>

                                    </Col>
                                    <Col md={8}>
                                        {resultSetData[v[0]].type === 1 ?
                                            (
                                                <FormGroup>
                                                    <Label htmlFor="exampleFormControlInput1">Related Product</Label>
                                                    <ProductTypeahead multiple={true}
                                                                      placeholder={'Please Select Product'}
                                                                      onChange={(options) => {
                                                                          let newResultSetData = resultSetData
                                                                          newResultSetData[v[0]] = {
                                                                              ...newResultSetData[v[0]],
                                                                              value: options
                                                                                  .map((v, i) => v.id)
                                                                          }

                                                                          setResultSetData({ ...newResultSetData })
                                                                      }}
                                                                      defaultSelectedId={resultSetData[v[0]].value}/>
                                                </FormGroup>
                                            )
                                            :
                                            (
                                                <FormGroup>
                                                    <Label htmlFor="exampleFormControlInput1">Question</Label>
                                                    <QuestionTypeahead multiple={false}
                                                                       placeholder={'Please Select Question'}
                                                                       onChange={(options) => {
                                                                           let newResultSetData = resultSetData
                                                                           newResultSetData[v[0]] = {
                                                                               ...newResultSetData[v[0]],
                                                                               value: options
                                                                                   .map((v, i) => v.id)
                                                                           }

                                                                           setResultSetData({ ...newResultSetData })
                                                                       }}
                                                                       defaultSelectedId={resultSetData[v[0]].value}/>
                                                </FormGroup>
                                            )
                                        }
                                    </Col>


                                </Row>

                            )
                        })}
                    </CardBody>

                    <CardFooter className="text-end">
                        <Btn attrBtn={{
                            color: 'primary',
                            className: 'm-r-15',
                            type: 'submit',
                            // onClick: () => {
                            //     console.log(questionData)
                            //     console.log(answerData)
                            //     console.log(resultSetData)
                            // }
                        }}
                        >Submit</Btn>
                        <Link to={'/content/skin-analyzer/question'}>
                            <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                        </Link>
                    </CardFooter>
                </Form>

            </Card>
        </Fragment>
    );
};

export default CreateSkinAnalyzerQuestionForms;