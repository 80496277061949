import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import TestimonialList from './TestimonialList';
import VideoTestimonialList from './VideoTestimonialList';

const TestimonialManagement = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Testimonial Management" parent="Homepage" title="Testimonial Management"/>
            <Container fluid={true}>
                <Row>
                    <Col xl="12">
                        <TestimonialList/>
                    </Col>
                    <Col xl="12">
                        <VideoTestimonialList/>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default TestimonialManagement;