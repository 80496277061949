import DataTable from 'react-data-table-component';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import axios from 'axios';
import { API_CDN_TEMP, API_STORE_PRODUCT } from '../../../api/server';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';
import SweetAlert from 'sweetalert2';
import { Btn, H6, Image } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { ProductCategoryTypeahead } from './ProductCategoryTypeahead';
import product1 from '../../../assets/images/ecommerce/product-table-1.png';
import { productColumns, style, style2 } from './mock';
import { CheckCircle, XCircle } from 'react-feather';

export const ProductDataTable = () => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [toggleCleared, setToggleCleared] = useState(false);

    const nav = useNavigate();

    const [tablePagination, setTablePagination] = useState({
        totalRows: 0,
        rowPerPage: 10,
        currentPage: 1,
    })

    const [formsText, setFormsText] = useState({
        search: '',
        categoryName: ''
    })

    // const columns = [
    //     {
    //         name: 'Created Date',
    //         selector: (row) => row.created_at.split('T')[0],
    //         sortable: true,
    //         center: true,
    //     },
    //     {
    //         name: 'Title',
    //         selector: (row) => row.title,
    //         sortable: true,
    //     }
    // ]

    useEffect(() => {

        axios.get(API_STORE_PRODUCT + '/?pagination=' + CreatePaginationEncoding(10, 0)).then(r => {
            if (r.status === 200) {
                return r.data['data'];
            }
        }).then(json => {
            let formattedData = []
            json['data'].map((v, i) => {
                formattedData = [...formattedData, generateColumn(v)]
            })

            setData(formattedData)
            setTablePagination({ ...tablePagination, totalRows: json['count']['all'] || 0 })
        }).catch(() => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
            })
        })
    }, [])


    const handleDelete = (id) => {
        // if (selectedRows.length > 0) {
        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.delete(API_STORE_PRODUCT + '/?' + 'id=' + id).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire({
                            title: 'Good Job!',
                            text: 'Data Deleted',
                            icon: 'success'
                        }).then(() => {
                            nav('/content/blog/post');
                        })
                    }
                }).catch(() => {
                    SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                    })
                })
            }
        });
        // }
    }

    const handleLoadWithFilter = (paginationLimit = tablePagination.rowPerPage, paginationOffset = 0, filter) => {
        setIsLoading(true);
        axios.get(API_STORE_PRODUCT + '/?pagination=' + CreatePaginationEncoding(paginationLimit, paginationOffset) + '&filter=' + CreateFilterEncoding({ ...filter })).then(r => {
            if (r.status === 200) {
                return r.data['data'];
            }
        }).then(json => {
            console.log(json['data'])
            if (json['data'] !== null) {
                let formattedData = []
                json['data'].map((v, i) => {
                    formattedData = [...formattedData, generateColumn(v)]
                })

                setData(formattedData)
                setTablePagination({ ...tablePagination, totalRows: json['count']['all'] || 0 })
            } else {
                setData([])
            }
        }).catch((err) => {
            SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                console.log(err)
            })
        })
        setIsLoading(false);
    }

    const onSearchSubmit = (e) => {
        e.preventDefault()

        console.log({
            title: formsText.search,
            category_name: formsText.categoryName
        })

        handleLoadWithFilter(tablePagination.rowPerPage, (tablePagination.currentPage - 1) * tablePagination.rowPerPage, {
            name_or_sku: formsText.search,
            category_name: formsText.categoryName
        })
    }

    const onPageChange = page => {
        handleLoadWithFilter(tablePagination.rowPerPage, (page - 1) * tablePagination.rowPerPage, {
            name_or_sku: formsText.search,
            category_name: formsText.categoryName
        })
        setTablePagination({ ...tablePagination, currentPage: page })
    };

    const onPaginationRowsChangePage = (newPerPage, page) => {
        setTablePagination({
            ...tablePagination,
            rowPerPage: newPerPage
        })
        handleLoadWithFilter(newPerPage, (page - 1) * newPerPage, {
            name_or_sku: formsText.search,
            category_name: formsText.categoryName
        })
    }

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const generateColumn = (v) => (
        {
            amount: 'Rp. ' + v.price,
            active: v.active ? <CheckCircle/> : <XCircle/>,
            image:
                <Image attrImage={{
                    src: v.images.main_photo_session !== '' ? API_CDN_TEMP + '/?load=' + v.images.main_photo_session : product1,
                    style: style,
                    alt: 'product'
                }}/>,
            Details:
                <div>
                    <H6>{v.name}</H6>
                    <span>SKU : {v.sku}</span><br/>
                    <span>BPOM : {v.bpom_number}</span><br/>
                    <span>HALAL : {v.halal_number}</span><br/>
                </div>,
            action:
                <div>
                    <span>
                        <Btn attrBtn={{
                            onClick: () => handleDelete(v.id),
                            style: style2,
                            className: 'btn btn-danger btn-xs',
                            type: 'button'
                        }}>Delete</Btn>
                    </span> &nbsp;&nbsp;
                    <span>
                        <Link to={'/store/product/create?edit=' + v.id}>
                            <Btn attrBtn={{
                                style: style2,
                                className: 'btn btn-success btn-xs',
                                type: 'button'
                            }}>Edit </Btn>
                        </Link>
                    </span>
                </div>
        }
    )

    return <>
        <Container fluid={true} className="datatables">
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="pb-0">
                            <Row>
                                <Col>
                                    <Link to={'/store/product/create'}>
                                        <Btn attrBtn={{
                                            className: 'btn btn-success btn-md',
                                            type: 'button'
                                        }}>New Product</Btn>
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Col md={12}>
                                <Form onSubmit={onSearchSubmit}>
                                    <Row className="justify-content-start align-items-end">
                                        <Col md="auto">
                                            <FormGroup className="row">
                                                <Label htmlFor="exampleFormControlSelect3">Search</Label>
                                                <Col sm="12">
                                                    <Input className="form-control" type="text"
                                                           placeholder={'Search Here'} onChange={(e) => setFormsText({
                                                        ...formsText,
                                                        search: e.target.value
                                                    })}/>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md="auto">
                                            <FormGroup className="row">
                                                <Label htmlFor="exampleFormControlSelect3">Category</Label>
                                                <Col sm="12">
                                                    <ProductCategoryTypeahead multiple={false}
                                                                              placeholder={'Please Select Product Category'}
                                                                              onChange={(options) => {
                                                                                  options.length > 0 &&
                                                                                  setFormsText({
                                                                                      ...formsText,
                                                                                      categoryName: options[0].name
                                                                                  })
                                                                              }}/>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md="auto">
                                            <Label htmlFor="exampleFormControlSelect3"></Label>
                                            <FormGroup className="row">
                                                <Btn attrBtn={{
                                                    color: 'primary',
                                                    className: 'm-r-15',
                                                    type: 'submit'
                                                }}>Search</Btn>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>

                            <Col md={12}>
                                <DataTable
                                    progressPending={isLoading}
                                    data={data}
                                    columns={productColumns}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={tablePagination.totalRows}
                                    onChangeRowsPerPage={onPaginationRowsChangePage}
                                    onChangePage={onPageChange}
                                    striped={true}
                                    center={true}
                                    noHeader
                                />
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>


    </>
}