// dashbaord
import ProductListContain from '../Views/Product/Product List';
import ProductCategoryList from '../Views/Product/Product Category List';
import CarouselManagement from '../Views/Homepage/CarouselManagement';
import CreateBlogPost from '../Views/Blog/CreateBlogPost';
import BlogPostList from '../Views/Blog/BlogPostList';
import SkinConcernManagement from '../Views/Skin/SkinConcernList';
import OthersHomepageManagement from '../Views/Homepage/OthersManagement';
import PromotionManagement from '../Views/Promotion/PromotionManagement';
import TermsConditionManagement from '../Views/Company/TermsConditionManagement';
import StoryManagement from '../Views/Company/StoryManagement';
import CreateProduct from '../Views/Product/Create Product';
import CreateProductCategory from '../Views/Product/Create Product Category';
import TestimonialManagement from '../Views/Homepage/TestimonialManagement';
import FAQManagement from '../Views/Company/FAQManagement';
import AboutUsManagement from '../Views/Company/AboutUsManagement';
import StorePartnerList from '../Views/Store/Partners';
import BlogCategoryList from '../Views/Blog/BlogCategoryList';
import { CollabManagement } from '../Views/Promotion/ColabManagement';
import SubscriptionEmailManagement from '../Views/Company/SubscriptionEmailManagement';
import CreateSkinAnalyzerQuestion from '../Views/Skin/CreateSkinAnalyzerQuestion';
import SkinAnalyzerManagement from '../Views/Skin/SkinAnalyzerManagement';
import CustomerContactManagement from '../Views/Company/CustomerContactManagement';
import ThemeManagement from '../Views/Homepage/ThemeManagement';
import LoginPages from '../Views/Auth/Login/LoginForm';
import ManageAccountPages from '../Views/Account';
import CreateSaveOurPlanetPost from '../Views/Blog/CreateSaveOurPlanetPost';
import SkinAnalyzerCollectionManagement from '../Views/Company/SkinAnalyzerCollectionManagement';
import { CreateCollabManagement } from '../Views/Promotion/CreateColabManagement';
import { CreatePopupItems } from '../Views/Homepage/CreatePopupItems';
import PopupManagement from '../Views/Homepage/PopupManagement';
import PopupCollectionManagement from '../Views/Company/PopupCollectionManagement';
import PrivacyPolicyManagement from '../Views/Company/PrivacyPolicyManagement';

export const authRoutes = [
    { path: `${process.env.PUBLIC_URL}/login`, Component: <LoginPages/> },
]

export const routes = [

    { path: `${process.env.PUBLIC_URL}/dashboard/default`, Component: <CarouselManagement/> },

    { path: `${process.env.PUBLIC_URL}/account/manage`, Component: <ManageAccountPages/> },

    //Product
    { path: `${process.env.PUBLIC_URL}/store/product/create`, Component: <CreateProduct/> },
    { path: `${process.env.PUBLIC_URL}/store/product/list`, Component: <ProductListContain/> },
    { path: `${process.env.PUBLIC_URL}/store/product/category-list`, Component: <ProductCategoryList/> },
    { path: `${process.env.PUBLIC_URL}/store/product/category/create`, Component: <CreateProductCategory/> },
    { path: `${process.env.PUBLIC_URL}/store/product/promotion`, Component: <PromotionManagement/> },
    { path: `${process.env.PUBLIC_URL}/store/product/collaboration`, Component: <CollabManagement/> },
    { path: `${process.env.PUBLIC_URL}/store/product/collaboration/create`, Component: <CreateCollabManagement/> },

    //Homepage
    { path: `${process.env.PUBLIC_URL}/content/homepage/carousel`, Component: <CarouselManagement/> },
    { path: `${process.env.PUBLIC_URL}/content/homepage/testimonial`, Component: <TestimonialManagement/> },
    { path: `${process.env.PUBLIC_URL}/content/homepage/others`, Component: <OthersHomepageManagement/> },
    { path: `${process.env.PUBLIC_URL}/content/homepage/popup`, Component: <PopupManagement/> },
    { path: `${process.env.PUBLIC_URL}/content/homepage/popup/create`, Component: <CreatePopupItems/> },
    { path: `${process.env.PUBLIC_URL}/content/homepage/theme`, Component: <ThemeManagement/> },
    { path: `${process.env.PUBLIC_URL}/content/skin/concern`, Component: <SkinConcernManagement/> },
    { path: `${process.env.PUBLIC_URL}/content/skin-analyzer/question`, Component: <SkinAnalyzerManagement/> },
    {
        path: `${process.env.PUBLIC_URL}/content/skin-analyzer/question/create`,
        Component: <CreateSkinAnalyzerQuestion/>
    },

    //Blog
    { path: `${process.env.PUBLIC_URL}/content/blog/post`, Component: <BlogPostList/> },
    { path: `${process.env.PUBLIC_URL}/content/blog/category`, Component: <BlogCategoryList/> },
    { path: `${process.env.PUBLIC_URL}/content/blog/post/create`, Component: <CreateBlogPost/> },
    { path: `${process.env.PUBLIC_URL}/content/blog/save-our-planet`, Component: <CreateSaveOurPlanetPost/> },

    //Company
    { path: `${process.env.PUBLIC_URL}/company/regulations/tnc`, Component: <TermsConditionManagement/> },
    { path: `${process.env.PUBLIC_URL}/company/regulations/privacy-policy`, Component: <PrivacyPolicyManagement/> },
    { path: `${process.env.PUBLIC_URL}/company/detail/story`, Component: <StoryManagement/> },
    { path: `${process.env.PUBLIC_URL}/company/detail/about`, Component: <AboutUsManagement/> },
    { path: `${process.env.PUBLIC_URL}/company/detail/faq`, Component: <FAQManagement/> },
    { path: `${process.env.PUBLIC_URL}/company/store/partner`, Component: <StorePartnerList/> },
    { path: `${process.env.PUBLIC_URL}/company/store/email`, Component: <SubscriptionEmailManagement/> },
    { path: `${process.env.PUBLIC_URL}/company/store/customer-contact`, Component: <CustomerContactManagement/> },
    {
        path: `${process.env.PUBLIC_URL}/company/skin-analyzer/data-collection`,
        Component: <SkinAnalyzerCollectionManagement/>
    },
    {
        path: `${process.env.PUBLIC_URL}/company/popup/data-collection`,
        Component: <PopupCollectionManagement/>
    },


];
