import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';
import { ProductDataTable } from './ProductDataTable';

const ProductListContain = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Products" title="Products List" mainTitle="Products List"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        {/*<Card>*/}
                        {/*    <CardHeader className="pb-0">*/}
                        {/*        <Link to={'/store/product/create'}>*/}
                        {/*            <Btn attrBtn={{*/}
                        {/*                className: 'btn btn-success btn-md',*/}
                        {/*                type: 'button'*/}
                        {/*            }}>New Product</Btn>*/}
                        {/*        </Link>*/}
                        {/*    </CardHeader>*/}
                        {/*    <CardBody>*/}
                        {/*<Stash_ProductTableData/>*/}
                        <ProductDataTable/>
                        {/*    </CardBody>*/}
                        {/*</Card>*/}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default ProductListContain;