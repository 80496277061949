import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Card, FormGroup } from 'react-bootstrap';
import { CardBody, CardFooter, Col, Form, Input, Label, Row } from 'reactstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { Btn } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { API_STORE_PRODUCT_CATEGORY } from '../../../api/server'
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';

const CreateProductCategoryForms = ({ id = 0 }) => {
    const [data, setData] = useState({
        id: 0,
        name: '',
    })

    useEffect(() => {
        if (id !== undefined && id !== null && id !== 0) {
            axios.get(API_STORE_PRODUCT_CATEGORY + '/?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({ id: id })).then(r => {
                if (r.status === 200) {
                    return r.data['data'];
                }
            }).then(json => {
                if (json['data'].length > 0) {
                    setData(json['data'][0])
                }
            }).catch(() => {
                SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data', icon: 'error' }).then(() => {
                })
            })
        }
    }, [id])


    const handlerSetFormString = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handlerSetFormNumeric = (e) => {
        setData({
            ...data,
            [e.target.name]: parseInt(e.target.value)
        })
    }

    const nav = useNavigate();

    const handlerSubmit = (e) => {
        e.preventDefault()

        if (id !== 0) {
            axios.put(API_STORE_PRODUCT_CATEGORY + '/', data).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        } else {
            axios.post(API_STORE_PRODUCT_CATEGORY + '/', data).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({ title: 'Good job!', text: 'Data Saving Success!', icon: 'success' }).then(() => {
                        nav('/store/product/category-list');
                    })
                } else {
                    SweetAlert.fire({ title: 'Failed!', text: 'Data Saving Failed', icon: 'error' }).then(() => {
                    })
                }
            })
        }


    }


    return (
        <Fragment>
            <Card>
                <HeaderCard title={(id === 0 ? 'Tambah' : 'Edit') + ' Kategori Produk'}/>
                <Form className="form theme-form" onSubmit={(e) => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Category Name</Label>
                                    <Input required name="name" value={data.name}
                                           onChange={(e) => handlerSetFormString(e)}
                                           className="form-control btn-square" type="text"
                                           placeholder="ABCD 0123456789"/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{
                            color: 'primary',
                            className: 'm-r-15',
                            type: 'submit',
                        }}
                        >Submit</Btn>
                        <Link to={'/store/product/category-list'}>
                            <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                        </Link>
                    </CardFooter>
                </Form>

            </Card>
        </Fragment>
    );
};

export default CreateProductCategoryForms;