import React from 'react'

import { FilePond } from 'react-filepond'
import { API_CDN_CNF } from '../../../api/server';


function DOWImageUploader({ image, setImage }) {
    return (
        <>
            <FilePond
                required
                allowMultiple={false}
                allowImageTransform={true}
                allowImageCrop={true}
                allowFileSizeValidation={true}

                files={image}
                onupdatefiles={setImage}

                maxFiles={1}
                imageResizeTargetWidth={1000}

                image
                imageCropAspectRatio={'1000:500'}
                acceptedFileTypes={['image/*']}
                maxFileSize={'2MB'}
                server={API_CDN_CNF}
                name="files"
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
            />
        </>

    )
}


export default DOWImageUploader
