import React, { Fragment, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';
import { Col, Container, Row } from 'reactstrap';
import { PopupDataTable } from './PopupDataTable';
import ActivePopupManagement from './ActivePopupManagement';

const PopupManagement = () => {
    const [selectedRows, setSelectedRows] = useState([]);

    return (
        <Fragment>
            <Breadcrumbs parent="Blog" title="Popup Management" mainTitle="Popup Management"/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <ActivePopupManagement/>
                    </Col>
                    <Col sm="12">
                        <PopupDataTable selectedRows={selectedRows} setSelectedRows={setSelectedRows}/>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default PopupManagement;