import React, { Fragment, useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import { API_ADDRESS_REGENCY } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { CreateFilterEncoding, CreatePaginationEncoding } from '../../../Utils/Base64/request';


export const RegencyTypeahead = ({
                                     multiple = false,
                                     placeholder = '',
                                     onChange,
                                     defaultSelectedId = [],
                                     required = false
                                 }) => {
    const [data, setData] = useState([])
    const [initialData, setInitialData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [wait, setWait] = useState(false)


    const [defaultValue, setDefaultValue] = useState([])

    useEffect(() => {
        axios.get(API_ADDRESS_REGENCY + '?pagination=' + CreatePaginationEncoding(5, 0)).then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            if (json['data'] !== null) {
                setData(json['data'])
                setInitialData(json['data'])
            }
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])

    useEffect(() => {
        if (defaultSelectedId.length > 0) {
            console.log('dsawwedwa1', defaultSelectedId.length)
            console.log('dsawwedwa1', defaultSelectedId)
            axios.get(API_ADDRESS_REGENCY + '?pagination=' + CreatePaginationEncoding(999999999, 0) + '&filter=' + CreateFilterEncoding({ id: parseInt(defaultSelectedId[0]) })).then(r => {
                if (r.status === 200) {
                    return r.data
                }
            }).then(json => {
                console.log('zxcxzcadsd', json['data'])
                if (json['data'] !== null) {
                    setDefaultValue(json['data'])
                } else {
                    setDefaultValue([])
                }
            }).catch(() => {
                SweetAlert.fire('Error!', 'Data Load Failed', 'error')
            })
        } else {
            setDefaultValue([])
        }
    }, [defaultSelectedId])

    useEffect(() => {
        // setWait(true);
        // if (!wait) {
        if (searchTerm !== '') {
            axios.get(API_ADDRESS_REGENCY + '?pagination=' + CreatePaginationEncoding(5, 0) + '&filter=' + CreateFilterEncoding({ name: searchTerm })).then(r => {
                if (r.status === 200) {
                    return r.data
                }
            }).then(json => {
                if (json['data'] !== null) {
                    setData(json['data'])
                } else {
                    setData([])
                }
                //
                // setTimeout(() => {
                // }, 100)

                setWait(false);
            }).catch(() => {
                setData([])
            })
        } else {
            setData(initialData)
        }
        // }
    }, [searchTerm])

    return (
        <Fragment>
            <Typeahead
                // inputProps={{
                //     required: required
                // }}
                onInputChange={(t, e) => {
                    setSearchTerm(t)
                }}
                onChange={onChange}
                id="province-typeahead"
                className="mt-2"
                clearButton
                labelKey="name"
                multiple={multiple}
                options={data}
                placeholder={placeholder}
                selected={defaultValue}
            />
        </Fragment>
    )
}