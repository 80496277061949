import { AlignCenter, Anchor, Command, Gift, Home, Mail, Package, Printer, Sun, User } from 'react-feather';

export const MENUITEMS = [
    {
        menutitle: 'Content',
        menucontent: 'Content,Widgets',
        Items: [
            {
                title: 'Homepage', icon: Home, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/content/homepage/carousel`, title: 'Carousel', type: 'link' },
                    {
                        path: `${process.env.PUBLIC_URL}/content/homepage/testimonial`,
                        title: 'Testimonial',
                        type: 'link'
                    },
                    { path: `${process.env.PUBLIC_URL}/content/homepage/theme`, title: 'Theme', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/content/homepage/popup`, title: 'Popup', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/content/homepage/others`, title: 'Others', type: 'link' },
                ]
            },
            {
                title: 'Blog', icon: AlignCenter, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/content/blog/post`, title: 'Blog', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/content/blog/category`, title: 'Blog Categories', type: 'link' },
                    {
                        path: `${process.env.PUBLIC_URL}/content/blog/save-our-planet`,
                        title: 'Save Our Planet',
                        type: 'link'
                    },
                ]
            },
            {
                title: 'Skin', icon: Sun, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/content/skin/concern`, title: 'Skin Concern', type: 'link' },
                ]
            },
            {
                title: 'Skin Analyzer', icon: Sun, type: 'sub', active: false, children: [
                    {
                        path: `${process.env.PUBLIC_URL}/content/skin-analyzer/question`,
                        title: 'Question',
                        type: 'link'
                    },
                ]
            }
        ]
    },
    {
        menutitle: 'Store',
        menucontent: 'Product,Widgets',
        Items: [
            {
                title: 'Product', icon: Package, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/store/product/list`, title: 'Product List', type: 'link' },
                    {
                        path: `${process.env.PUBLIC_URL}/store/product/category-list`,
                        title: 'Category List',
                        type: 'link'
                    },
                ]
            },
            {
                title: 'Promotion', icon: Gift, type: 'sub', active: false, children: [
                    {
                        path: `${process.env.PUBLIC_URL}/store/product/promotion`,
                        title: 'Promotion Config',
                        type: 'link'
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/store/product/collaboration`,
                        title: 'Collaboration Config',
                        type: 'link'
                    },
                ]
            }
        ]
    },
    {
        menutitle: 'Company',
        menucontent: 'Product,Widgets',
        Items: [
            {
                title: 'Regulations', icon: Command, type: 'sub', active: false, children: [
                    {
                        path: `${process.env.PUBLIC_URL}/company/regulations/tnc`,
                        title: 'Terms & Conditions',
                        type: 'link'
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/company/regulations/privacy-policy`,
                        title: 'Privacy Policy',
                        type: 'link'
                    },

                ]
            },
            {
                title: 'Company Detail', icon: Printer, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/company/detail/about`, title: 'About', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/company/detail/faq`, title: 'FAQ', type: 'link' },
                    { path: `${process.env.PUBLIC_URL}/company/detail/story`, title: 'Story', type: 'link' },
                ]
            },
            {
                title: 'Partners', icon: Anchor, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/company/store/partner`, title: 'Partner', type: 'link' },
                ]
            },
            {
                title: 'Data Collection', icon: Mail, type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/company/store/email`, title: 'Subscription', type: 'link' },
                    {
                        path: `${process.env.PUBLIC_URL}/company/store/customer-contact`,
                        title: 'Customer Contact',
                        type: 'link'
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/company/skin-analyzer/data-collection`,
                        title: 'Skin Analyzer',
                        type: 'link'
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/company/popup/data-collection`,
                        title: 'Popup',
                        type: 'link'
                    },
                ]
            },
        ]
    },
    {
        menutitle: '',
        menucontent: 'Akun',
        Items: [
            {
                title: 'Akun', icon: User, type: 'link', path: `${process.env.PUBLIC_URL}/account/manage`
            },
        ]
    },


    /*    {
            menutitle: 'General',
            menucontent: 'Dashboards,Widgets',
            Items: [
                {
                    title: 'Dashboard', icon: Home, type: 'sub', active: false, children: [
                        { path: `${process.env.PUBLIC_URL}/dashboard/default`, title: 'Default', type: 'link' },
                        { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, title: 'Ecommerce', type: 'link' },
                    ]
                }
            ]

        },
        {
            menutitle: 'Starter',
            menucontent: 'Ready to use Apps',
            Items: [
                {
                    title: 'Starter kit', icon: Anchor, type: 'sub', active: false, children: [
                        { path: `${process.env.PUBLIC_URL}/starter-kits/sample-page`, title: 'Sample Page', type: 'link' },
                    ]
                }
            ]
        },
        {
            menutitle: 'Support',
            menucontent: '',
            Items: [
                {
                    title: 'Raise Support', icon: Headphones, type: 'sub', active: false, children: [
                        { title: 'Raise Ticket', type: 'exteral_link', path: 'http://support.pixelstrap.com/help-center' },
                    ]
                }
            ]
        },*/


]