import { Card, FormGroup } from 'react-bootstrap';
import { CardBody, CardFooter, Col, Form, Label, Row } from 'reactstrap';
import React, { Fragment, useEffect, useState } from 'react';
import { Btn } from '../../../AbstractElements';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { QuestionTypeahead } from '../../../Components/Common/Component/QuestionTypeahead';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';

const InitialQuestionForm = () => {
    // const blankAnswerData = {
    //     timestamp: window.performance.now(),
    //     answer_code: '',
    //     answer_text: ''
    // }
    //
    // const resultSetAction = {
    //     type: 1,
    //     value: []
    // }
    //
    // const [questionData, setQuestionData] = useState({
    //     question_text: '',
    //     answer_count: 1,
    //     result_set: {},
    //     answer_set: {}
    // })
    //
    // const [answerData, setAnswerData] = useState([blankAnswerData])
    //
    // const [resultSetData, setResultSetData] = useState({})
    //
    // const [searchParams, setSearchParams] = useSearchParams();
    // const id = searchParams.get('edit')
    //
    // useEffect(() => {
    //     const res = PrintCombination([...answerData.map((v, i) => i)], answerData.length, questionData.answer_count)
    //
    //     let map = {}
    //
    //     res.map((v, i) => {
    //         if (resultSetData[v.join('')] === undefined) {
    //             map[v.join('')] = resultSetAction
    //         } else {
    //             map[v.join('')] = resultSetData[v.join('')]
    //         }
    //     })
    //
    //     setResultSetData(map)
    //
    // }, [answerData, questionData.answer_count])
    //
    //
    // useEffect(() => {
    //     if (id !== undefined && id !== null && id !== 0) {
    //         axios.get(API_SKIN_ANALYZER_QUESTION + '/?pagination=' + CreatePaginationEncoding(1, 0) + '&filter=' + CreateFilterEncoding({ id: parseInt(id) })).then(r => {
    //             if (r.status === 200) {
    //                 return r.data['data'];
    //             }
    //         }).then(json => {
    //             if (json['data'].length > 0) {
    //                 const _d = json['data'][0];
    //                 setQuestionData(_d)
    //                 setResultSetData(JSON.parse(_d.result_set));
    //                 setAnswerData(JSON.parse(_d.answer_set));
    //
    //                 console.log(JSON.parse(_d.result_set))
    //
    //             }
    //         }).catch((err) => {
    //             SweetAlert.fire({ title: 'Error!', text: 'Error while Fetch Data ' + err, icon: 'error' }).then(() => {
    //             })
    //         })
    //     }
    // }, [id])
    //

    const [data, setData] = useState({})

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_SA_DEFAULT_QUESTION').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setDefaultQuestion(json['data'])
        }).catch((err) => {
            SweetAlert.fire('Error!', 'Data Load Failed\n' + err, 'error')
        })
    }, [])

    const nav = useNavigate();

    const handlerSubmit = (e) => {
        e.preventDefault()

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_SA_DEFAULT_QUESTION',
            value: JSON.stringify({
                data: defaultQuestion
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })

    }

    const [defaultQuestion, setDefaultQuestion] = useState({})

    return (
        <Fragment>
            <Card>
                <HeaderCard title={'Skin Analyzer Question Flow'}/>
                <Form className="form theme-form" onSubmit={(e) => handlerSubmit(e)}>
                    <CardBody>
                        <Row style={{ marginBottom: 24 }}>
                            <Col md={12}>
                                <FormGroup>
                                    <Label>Initial Question</Label>
                                    <QuestionTypeahead multiple={false}
                                                       placeholder={'Please Select Question'}
                                                       onChange={(options) => {
                                                           setDefaultQuestion(options
                                                               .map((v, i) => v.id))
                                                       }}
                                                       defaultSelectedId={defaultQuestion}/>
                                    {/*<Input required name="question_text" value={questionData.question_text}*/}
                                    {/*       onChange={(e) => {*/}
                                    {/*           setQuestionData({ ...questionData, question_text: e.target.value })*/}
                                    {/*       }}*/}
                                    {/*       className="form-control btn-square" type="text"*/}
                                    {/*       placeholder="Insert a Question"/>*/}
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>

                    <CardFooter className="text-end">
                        <Btn attrBtn={{
                            color: 'primary',
                            className: 'm-r-15',
                            type: 'submit',
                        }}
                        >Set Initial Question</Btn>
                    </CardFooter>
                </Form>

            </Card>
        </Fragment>
    );
};

export default InitialQuestionForm;