import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';

const RunningTextManagement = () => {
    const [data, setData] = useState({
        text: ''
    })

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/HP_RUNNING_TEXT').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData({ ...json })
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        })
    }, [])


    const handlerSubmit = (e) => {
        e.preventDefault();

        axios.post(API_CONFIGURATOR + '/', {
            key: 'HP_RUNNING_TEXT',
            value: JSON.stringify(data)
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        })
    }


    return (
        <Fragment>
            <Card>
                <HeaderCard title="Manage Running Text"/>
                <Form className="form theme-form" onSubmit={e => handlerSubmit(e)}>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="exampleFormControlInput1">Running Text</Label>
                                    <Input className="form-control" type="text"
                                           value={data.text}
                                           onChange={e => setData({ ...data, text: e.target.value })}

                                           placeholder="Dapatkan Hemat 10%"/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: 'primary', className: 'm-r-15', type: 'submit' }}>Submit</Btn>
                        <Btn attrBtn={{ color: 'light' }}>Cancel</Btn>
                    </CardFooter>
                </Form>
            </Card>
        </Fragment>
    );
};

export default RunningTextManagement;