import utf8 from 'utf8/utf8';
import base64 from 'base-64';

export const CreatePaginationEncoding = (count, offset) => {
    let b64Str = base64.encode(JSON.stringify({ count: count, offset: offset }))
    return b64Str.replaceAll('+', '.').replaceAll('/', '-').replaceAll('=', '-')
}

export const CreateFilterEncoding = (jsonData) => {
    const jsonString = JSON.stringify(jsonData)
    let uft8Str = utf8.encode(jsonString);
    let b64Str = base64.encode(uft8Str);
    return b64Str.replaceAll('+', '.').replaceAll('/', '-').replaceAll('=', '-')
}