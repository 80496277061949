import React, { Fragment } from 'react';
import { Btn } from '../../../AbstractElements';
import { Spinner } from 'reactstrap';

const LoaderButton = ({ activeLoading = false, attrBtn = null }) => {
    return (
        <Fragment>
            {activeLoading ?
                <Btn attrBtn={attrBtn !== null ? { ...attrBtn } : {
                    color: 'primary',
                    className: 'm-r-15',
                    type: 'submit',
                }}
                >
                    <Spinner size="sm" attrSpinner={{ className: 'loader-2' }}/>

                    {'    Submitting...'}
                </Btn>
                : <Btn attrBtn={attrBtn !== null ? { ...attrBtn } : {
                    color: 'primary',
                    className: 'm-r-15',
                    type: 'submit',
                }}
                >
                    Submit
                </Btn>
            }
        </Fragment>
    )
}

export default LoaderButton