import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumbs } from '../../../AbstractElements';

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { CustomerContactDataTables } from './CustomerContactDataTable';
import Drawer from 'react-modern-drawer';
import { CardBody, Col, Form, Input, Label, Row } from 'reactstrap';
import { Card, FormGroup } from 'react-bootstrap';
import HeaderCard from '../../../Components/Common/Component/HeaderCard';


const CustomerContactManagement = () => {

    const [drawerState, setDrawerState] = React.useState({
        detail: false,
    })

    const closeAllDrawer = () => {
        setDrawerState({
            detail: false,
        })
    }

    const [selectedData, setSelectedData] = useState({})

    useEffect(() => {
        // console.log('SD', selectedData)
        if (selectedData.id !== undefined) {
            setDrawerState({
                detail: true
            })
        }
    }, [selectedData])

    return (
        <Fragment>
            <Breadcrumbs parent="Company" title="Customer Contact List" mainTitle="Customer Contact List"/>
            <CustomerContactDataTables setSelectedData={setSelectedData}/>
            <Drawer
                open={drawerState.detail}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <Card>
                    <HeaderCard title={'Data Detail'}/>
                    <Form className="form theme-form">
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input disabled value={selectedData.name}/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input disabled value={selectedData.email}/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Message</Label>
                                        <Input disabled type={'textarea'} value={selectedData.message}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
            </Drawer>
        </Fragment>
    );

};

export default CustomerContactManagement;