import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, H5 } from '../../../AbstractElements';
import InitialQuestionForm from './InitialQuestionForm';
import { SkinAnalyzerQuestionDataTableV2 } from './SkinAnalyzerQuestionDataTableV2';

const SkinAnalyzerManagement = () => {
    const [selectedRows, setSelectedRows] = useState([]);

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Skin Analyzer Management" parent="Skin Analyzer" title="Skin Analyzer Management"/>
            <Container fluid={true}>
                <InitialQuestionForm/>

                <Row>
                    <Col xl="12">
                        <Card>
                            <CardHeader>
                                <H5>Skin Analyzer Question Management</H5>
                            </CardHeader>
                            <CardBody className="pt-0">
                                {/*<SkinAnalyzerQuestionDataTable selectedRows={selectedRows}*/}
                                {/*                               setSelectedRows={setSelectedRows}/>*/}
                                <SkinAnalyzerQuestionDataTableV2 selectedRows={selectedRows}
                                                                 setSelectedRows={setSelectedRows}/>
                                {/*<CreateSkinAnalyzerQuestionForms/>*/}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>


        </Fragment>
    );
};
export default SkinAnalyzerManagement;