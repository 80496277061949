import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Edit, Trash2 } from 'react-feather';
import TodoContext from '../../../_helper/Todo';
import { Btn, H4, LI, UL } from '../../../AbstractElements';
import FAQForm from './FAQForm';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';

const FAQList = () => {
    const { allTodos, removeItems } = useContext(TodoContext);
    useEffect(() => {
    }, [allTodos]);

    const [addTask, setAddTask] = useState('');
    const [data, setData] = useState([])
    const [selectedData, setSelectedData] = useState({})

    const handleRemove = (ts) => {
        const allDataProcessed = data.filter((v, i, a) => {
            return v.timestamp !== ts
        })

        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.post(API_CONFIGURATOR + '/', {
                    key: 'CF_FAQ',
                    value: JSON.stringify({
                        data: [
                            ...allDataProcessed,
                        ]
                    })
                }).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire('Good Job!', 'Data Delete Success', 'success')
                        setData([
                                ...allDataProcessed,
                            ]
                        )
                    }
                }).catch(() => {
                    SweetAlert.fire('Failed!', 'Data Delete Failed', 'error')
                })
            }
        });
    };

    const openTaskWrapper = () => {
        setAddTask(' visible');
        document.getElementById('addTaskBtn').classList.add('hide');
    };

    useEffect(() => {
        openTaskWrapper()
    }, [selectedData])

    useEffect(() => {
        axios.get(API_CONFIGURATOR + '/CF_FAQ').then(r => {
            if (r.status === 200) {
                return r.data
            }
        }).then(json => {
            setData(json.data)
        }).catch(() => {
            SweetAlert.fire('Error!', 'Data Load Failed', 'error')
        }).finally(() => {
            setAddTask('');
            document.getElementById('addTaskBtn').classList.remove('hide');
        })
    }, [])

    return (
        <Fragment>
            <div className="todo-list-body">
                <UL attrUL={{ className: 'simple-list', id: 'todo-list' }}>
                    {data.length > 0 ?
                        data.map((todo, index) =>
                            <LI attrLI={{ className: 'task ' + todo.status }} key={index}>
                                <div className="task-container">
                                    <H4 attrH4={{ className: 'task-label' }}>{todo.question}</H4>
                                    <span className="task-action-btn">
                                        <span className="action-box large delete-btn" title="Delete Task"
                                              onClick={() => handleRemove(todo.timestamp)}><Trash2/></span>
                                        <span className="action-box large complete-btn" title="Mark Complete"
                                              onClick={(e) => {
                                                  setSelectedData({ ...todo })
                                              }}>
                                            <Edit/>
                                        </span>
                                    </span>
                                </div>
                            </LI>
                        ) : ''}
                </UL>
            </div>
            <div className="todo-list-footer">
                <div className="add-task-btn-wrapper">
                    <span className="add-task-btn" id="addTaskBtn">
                        <Btn attrBtn={{
                            color: 'primary',
                            onClick: () => {
                                if (selectedData !== null) {
                                    setSelectedData(null)
                                } else {
                                    openTaskWrapper()
                                }
                            }
                        }}>
                            Add New
                        </Btn>
                    </span>
                </div>
                <div className={'new-task-wrapper' + addTask}>
                    <FAQForm editData={selectedData} allData={data} setParentData={setData}
                             setAddTask={setAddTask}/>
                </div>
            </div>
        </Fragment>
    );
};
export default FAQList;