import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import axios from 'axios';
import { API_CONFIGURATOR } from '../../../api/server';
import SweetAlert from 'sweetalert2';
import { value } from 'lodash/seq';
import TestimonialImageUploader from './upload_testimonial_image';
import { defaultData } from './data';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyCustomUploadAdapterPlugin } from '../../../Utils/ck5_upload_adapter';


const StoryForm = ({ editData, allData, setAddTask, setParentData }) => {
    const closeTaskWrapper = () => {
        setAddTask('');
        document.getElementById('addTaskBtn').classList.remove('hide');
    };

    const [data, setData] = useState(defaultData)

    const [image, setImage] = useState([])
    const [secondImage, setSecondImage] = useState([])

    useEffect(() => {
        if (editData !== null && editData !== {}) {
            setData(editData)
        } else {
            setData(defaultData)
        }
    }, [editData])

    useEffect(() => {
        if (data.image_id !== '') {
            setImage([{
                source: data.image_id,
                options: { type: 'local' }
            }])
        } else {
            setImage([])
        }

        if (data.second_image_id !== '') {
            setSecondImage([{
                source: data.second_image_id,
                options: { type: 'local' }
            }])
        } else {
            setSecondImage([])
        }
    }, [data.image_id, data.second_image_id])

    const handlerOnSubmit = (e) => {
        e.preventDefault()

        let img_id = ''
        let img_id_nd = ''

        if (image.length > 0) {
            img_id = image[0].serverId
        } else {
            img_id = data.image_id
        }

        if (secondImage.length > 0) {
            img_id_nd = secondImage[0].serverId
        } else {
            img_id_nd = data.second_image_id
        }


        const allDataProcessed = editData !== null ? allData.filter((v, i, a) => {
            return v.timestamp !== editData.timestamp
        }) : allData

        axios.post(API_CONFIGURATOR + '/', {
            key: 'CF_OUR_STORY',
            value: JSON.stringify({
                data: [
                    ...allDataProcessed,
                    {
                        ...data,
                        image_id: img_id,
                        second_image_id: img_id_nd,
                        timestamp: window.performance.now(),
                    }
                ]
            })
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire('Good Job!', 'Data Saving Success', 'success')
            }
        }).catch(() => {
            SweetAlert.fire('Failed!', 'Data Saving Failed', 'error')
        }).then(() => {
            setData(defaultData)
            closeTaskWrapper()
            setParentData([
                ...allDataProcessed,
                {
                    ...data,
                    image_id: img_id,
                    second_image_id: img_id_nd,
                    timestamp: window.performance.now(),
                }
            ])
        })

    }

    return (
        <Fragment>
            <Form onSubmit={handlerOnSubmit}>
                <Row>
                    <Col md={4} sm={12} lg={4}>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Primary Image (390 x 455)</Label>
                            <TestimonialImageUploader ratio={'390:455'} resizeWidth={390} resizeHeight={455}
                                                      image={image} setImage={setImage}/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Secondary Image (325 x 385)</Label>
                            <TestimonialImageUploader ratio={'325:385'} resizeWidth={325} resizeHeight={385}
                                                      image={secondImage} setImage={setSecondImage}/>
                        </FormGroup>
                    </Col>


                    <Col md={8} sm={12} lg={8}>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Order</Label>
                            <Input
                                value={data.order}
                                onChange={(e) => {
                                    setData({ ...data, order: parseInt(e.target.value) })
                                }}
                                className="form-control" type="number" max={5} placeholder="5"/>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">Title</Label>
                            <Input
                                value={data.title}
                                onChange={(e) => {
                                    setData({ ...data, title: e.target.value })
                                }}
                                className="form-control" type="text" placeholder="ABCDEFGHIJKLMNOPQRSTUVWXYZ"/>
                        </FormGroup>
                        <FormGroup style={{}}>
                            <Label htmlFor="exampleFormControlInput1">Content</Label>

                            <CKEditor
                                editor={ClassicEditor}
                                data={data.content}
                                config={{
                                    extraPlugins: [MyCustomUploadAdapterPlugin],
                                }}
                                onChange={(event, editor) => {
                                    const e = editor.getData();
                                    // setEditorData(data)
                                    setData({ ...data, content: e })
                                }}
                            />

                            {/*<Input*/}
                            {/*    value={data.content}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        setData({ ...data, content: e.target.value })*/}
                            {/*    }}*/}
                            {/*    className="form-control" type="textarea" placeholder="ABCDEFGHIJKLMNOPQRSTUVWXYZ"/>*/}
                        </FormGroup>
                    </Col>


                    <Col md={12} lg={12} sm={12} style={{ margin: '16px 0' }}>
                        <Btn attrBtn={{
                            color: 'danger',
                            className: 'cancel-btn',
                            id: 'close-task-panel',
                            onClick: closeTaskWrapper
                        }}>Cancel</Btn>
                        <Btn attrBtn={{
                            color: 'success',
                            className: 'ms-3 add-new-task-btn',
                            id: 'add-task',
                            type: 'submit',
                        }}>
                            Save
                        </Btn>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )

}

export default StoryForm